import axios from "axios";
import router from "../router/index";
import jwt_decode from "jwt-decode";

const auth = {
  state: {
    user: null,
  },
  mutations: {
    SET_USER: (state, user) => {
      state.user = user;
    },
    UNSET_USER: (state) => {
      state.user = null;
    },
  },
  actions: {
    login: async ({ commit, dispatch }, cred) => {
      // dispatch("setGlobalLoading", { status: true, message: "Logging In..." });
      try {
        const result = await axios.post(`/auth/attempt`, cred);
        const { user } = jwt_decode(result.data.access_token);
        commit("SET_USER", user);
      } catch (err) {
        console.log(err);
        throw new Error("Login Failed");
        // dispatch("setGlobalLoading", { status: false });
      }
    },
    logout: async ({ commit, dispatch }) => {
      await axios
        .post(`/auth/logout`)
        .then(() => {
          commit("UNSET_USER");
          // router.push("/");
          dispatch("setNotification", ["Logged Out", "success"]);
        })
        .catch(() => {
          dispatch("setNotification", ["Logging Out Failed", "danger"]);
          throw "Logout Failed";
        });
    },
    refreshToken: async ({ commit, state, dispatch }) => {
      try {
        const result = await axios.post(`/auth/refresh`);
        if (result.data) {
          const { user } = jwt_decode(result.data.access_token);
          commit("SET_USER", user);
        } else {
          throw "No Token";
        }
      } catch (err) {
        if (state.user) {
          dispatch("logout");
        }
      }
    },
  },
};

export default auth;

<template>
  <span>
    <div class="adswrapper">
      <Adsense
        data-ad-client="ca-pub-7578943240214333"
        data-ad-slot="2396693351"
      >
      </Adsense>
    </div>
    <span>
      <div class="add-game-modal-bg" v-if="addGameOpen">
        <div class="add-game-modal">
          <div class="add-game-modal-header">
            Add Games
            <span class="close-button" v-on:click="addGameOpen = false"
              ><i class="fas fa-times-circle"></i
            ></span>
          </div>
          <div class="game-modal-rows">
            <table>
              <tr>
                <th>Start Date</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Park Name</th>
                <th>Diamond</th>
                <th>Division</th>
                <th>Away Team</th>
                <th>Home Team</th>
                <th></th>
              </tr>
              <tr v-for="(row, key) in addGameRows" :key="key">
                <td>
                  <label>
                    <input
                      type="text"
                      placeholder="YYYY/MM/DD"
                      style="font-size: 12px; width: 120px"
                      v-model="row.start_date"
                    />
                  </label>
                </td>
                <td>
                  <label>
                    <input
                      type="text"
                      style="font-size: 12px; width: 80px"
                      placeholder="08:00:00"
                      v-model="row.start_time"
                    />
                  </label>
                </td>
                <td>
                  <label>
                    <input
                      type="text"
                      placeholder="08:00:00"
                      style="font-size: 12px; width: 80px"
                      v-model="row.end_time"
                    />
                  </label>
                </td>
                <td>
                  <label>
                    <input
                      type="text"
                      v-model="row.park_name"
                      style="font-size: 12px; width: 80px"
                      placeholder="Park"
                    />
                  </label>
                </td>
                <td>
                  <label>
                    <input
                      type="text"
                      style="font-size: 12px; width: 80px"
                      v-model="row.diamond_signifier"
                      placeholder="Diamond"
                    />
                  </label>
                </td>
                <td>
                  <label>
                    <select
                      name="away_team"
                      class="add-game"
                      id=""
                      v-model="row.division_id"
                    >
                      <option
                        :value="division.division_name"
                        v-for="(division, key) in computedDivisions"
                        :key="key"
                      >
                        {{ division.division_name }}
                      </option>
                    </select>
                  </label>
                </td>
                <td style="max-width: 300px">
                  <label>
                    <select
                      name="away_team"
                      class="add-game"
                      id=""
                      v-model="row.select_away_team"
                      style="font-size: 12px; padding: 10px"
                    >
                      <optgroup label="Teams">
                        <option
                          :value="team.id"
                          v-for="(team, key) in teams"
                          :key="key"
                        >
                          {{ team.name }}
                        </option>
                      </optgroup>
                      <optgroup label="Winner Of">
                        <option
                          :value="'winner-of-' + game.id"
                          v-for="game of games"
                          :key="game.id"
                        >
                          Winner of
                          {{
                            moment(game.start_time, "hh:mm:ss").format("h:mm A")
                          }}
                          on
                          {{ moment(game.start_date).format("YYYY-MM-DD") }} -
                          {{ game.diamond_signifier }} (#{{ game.id }})
                        </option>
                      </optgroup>
                      <optgroup label="Loser of">
                        <option
                          :value="'loser-of-' + game.id"
                          v-for="game of games"
                          :key="game.id"
                        >
                          Loser of
                          {{
                            moment(game.start_time, "hh:mm:ss").format("h:mm A")
                          }}
                          on
                          {{ moment(game.start_date).format("YYYY-MM-DD") }} -
                          {{ game.diamond_signifier }} (#{{ game.id }})
                        </option>
                      </optgroup>

                      <optgroup
                        v-for="division of computedDivisions"
                        :key="division.division_name"
                        :label="'Placed in ' + division.division_name"
                      >
                        <option
                          :value="
                            'placed-' + team + '-' + division.division_name
                          "
                          v-for="team in division.teams.length"
                          :key="team"
                        >
                          {{ ordinal(team) }} Place -
                          {{ division.division_name }}
                        </option>
                      </optgroup>
                    </select>
                  </label>
                </td>
                <td style="max-width: 300px">
                  <label>
                    <select
                      name="away_team"
                      class="add-game"
                      id=""
                      v-model="row.select_home_team"
                      style="font-size: 12px; padding: 10px"
                    >
                      <optgroup label="Teams">
                        <option
                          :value="team.id"
                          v-for="(team, key) in teams"
                          :key="key"
                        >
                          {{ team.name }}
                        </option>
                      </optgroup>
                      <optgroup label="Winner Of">
                        <option
                          :value="'winner-of-' + game.id"
                          v-for="game of games"
                          :key="game.id"
                        >
                          Winner of
                          {{
                            moment(game.start_time, "hh:mm:ss").format("h:mm A")
                          }}
                          on
                          {{ moment(game.start_date).format("YYYY-MM-DD") }} -
                          {{ game.diamond_signifier }} (#{{ game.id }})
                        </option>
                      </optgroup>
                      <optgroup label="Loser of">
                        <option
                          :value="'loser-of-' + game.id"
                          v-for="game of games"
                          :key="game.id"
                        >
                          Loser of
                          {{
                            moment(game.start_time, "hh:mm:ss").format("h:mm A")
                          }}
                          on
                          {{ moment(game.start_date).format("YYYY-MM-DD") }} -
                          {{ game.diamond_signifier }} (#{{ game.id }})
                        </option>
                      </optgroup>

                      <optgroup
                        v-for="division of computedDivisions"
                        :key="division.division_name"
                        :label="'Placed in ' + division.division_name"
                      >
                        <option
                          :value="
                            'placed-' + team + '-' + division.division_name
                          "
                          v-for="team in division.teams.length"
                          :key="team"
                        >
                          {{ ordinal(team) }} Place -
                          {{ division.division_name }}
                        </option>
                      </optgroup>
                    </select>
                  </label>
                </td>
                <td>
                  <div class="plus-button" v-on:click="addGameRow()">
                    <i class="fas fa-plus-circle"></i>
                  </div>
                </td>
              </tr>
            </table>
            <a
              class="button button-purple"
              style="margin-left: 10px"
              v-on:click="submitNewGames()"
            >
              Save New Games
            </a>
          </div>
        </div>
      </div>
      <div class="add-game-modal-bg" v-if="postponeGameOpen">
        <div class="add-game-modal">
          <div class="add-game-modal-header">
            Postpone Games
            <span class="close-button" v-on:click="postponeGameOpen = false"
              ><i class="fas fa-times-circle"></i
            ></span>
          </div>
          <div class="game-modal-rows">
            <table>
              <tr class="table-header">
                <th>Start Date</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Park Name</th>
                <th>Diamond</th>
                <th>Division</th>
                <th>Away Team</th>
                <th>Home Team</th>
                <th>Reason</th>
              </tr>
              <tr
                v-for="(row, key) in selectedGames"
                class="standard-table-row"
                :key="key"
              >
                <td>
                  <label>
                    {{ row.new_date }}
                  </label>
                </td>
                <td>
                  <label>
                    {{ row.start_time }}
                  </label>
                </td>
                <td>
                  <label>
                    {{ row.end_time }}
                  </label>
                </td>
                <td>
                  <label>
                    {{ row.park_name }}
                  </label>
                </td>
                <td>
                  <label>
                    {{ row.diamond_signifier }}
                  </label>
                </td>
                <td>
                  <label>
                    {{ row.division_id }}
                  </label>
                </td>
                <td>
                  <label v-if="row.awayTeam">
                    {{ row.awayTeam.name }}
                  </label>
                  <label v-if="!row.awayTeam">
                    {{ row.away_team_freetext }}
                  </label>
                </td>
                <td>
                  <label v-if="row.homeTeam">
                    {{ row.homeTeam.name }}
                  </label>
                  <label v-if="!row.homeTeam">
                    {{ row.home_team_freetext }}
                  </label>
                </td>
                <td>
                  <select name="reason" id="" v-model="row.reschedule_reason">
                    <option value="double-booking">Double Booking</option>
                    <option value="uic-cancels">UIC Cancels</option>
                    <option value="umpire-cancels-on-site">
                      Umpire Cancels On Site
                    </option>
                    <option value="away-team-cancels">Away Team Cancels</option>
                    <option value="home-team-cancels">Home Team Cancels</option>
                    <option value="other">Other</option>
                  </select>
                </td>
              </tr>
            </table>
            <div class="post-an-announcement">
              Do you want to post an announcement about this?
            </div>
            <div class="pills">
              <div
                :class="[
                  'pill',
                  { selected: postponeAnnouncementSwitch === true },
                ]"
                v-on:click="
                  postponeAnnouncementSwitch = true;
                  newAnnouncement.title = 'Games Postponed';
                "
              >
                Yes
              </div>
              <div
                :class="[
                  'pill',
                  { selected: postponeAnnouncementSwitch === false },
                ]"
                v-on:click="postponeAnnouncementSwitch = false"
              >
                No
              </div>
            </div>
            <div class="form-padding" v-if="postponeAnnouncementSwitch">
              <label for="">
                <span>Title</span>
                <input type="text" v-model="newAnnouncement.title" />
              </label>
              <label for="">
                <span>Announcement</span>
                <textarea v-model="newAnnouncement.description"></textarea>
              </label>
            </div>
            <a
              class="button button-purple"
              style="margin-left: 10px"
              v-on:click="submitPostponedGames()"
            >
              Update Games
            </a>
          </div>
        </div>
      </div>
      <div class="add-game-modal-bg" v-if="rescheduleGameOpen">
        <div class="add-game-modal">
          <div class="add-game-modal-header">
            Reschedule Games
            <span class="close-button" v-on:click="rescheduleGameOpen = false"
              ><i class="fas fa-times-circle"></i
            ></span>
          </div>
          <div class="game-modal-rows">
            <table>
              <tr class="table-header">
                <th>Start Date</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Park Name</th>
                <th>Diamond</th>
                <th>Division</th>
                <th>Away Team</th>
                <th>Home Team</th>
                <th></th>
              </tr>
              <tr
                v-for="(row, key) in selectedGames"
                :key="key"
                class="standard-table-row"
              >
                <td>
                  <label>
                    <input
                      type="text"
                      placeholder="YYYY/MM/DD"
                      v-model="row.new_date"
                    />
                  </label>
                </td>
                <td>
                  <label>
                    <input
                      type="text"
                      placeholder="08:00:00"
                      v-model="row.start_time"
                    />
                  </label>
                </td>
                <td>
                  <label>
                    <input
                      type="text"
                      placeholder="08:00:00"
                      v-model="row.end_time"
                    />
                  </label>
                </td>
                <td>
                  <label>
                    <input
                      type="text"
                      v-model="row.park_name"
                      placeholder="Park"
                    />
                  </label>
                </td>
                <td>
                  <label>
                    <input
                      type="text"
                      v-model="row.diamond_signifier"
                      placeholder="Diamond"
                    />
                  </label>
                </td>
                <td>
                  <label>
                    {{ row.division_id }}
                  </label>
                </td>
                <td>
                  <label v-if="row.awayTeam">
                    {{ row.awayTeam.name }}
                  </label>
                  <label v-if="!row.awayTeam">
                    {{ row.away_team_freetext }}
                  </label>
                </td>
                <td>
                  <label v-if="row.homeTeam">
                    {{ row.homeTeam.name }}
                  </label>
                  <label v-if="!row.homeTeam">
                    {{ row.home_team_freetext }}
                  </label>
                </td>
                <td>
                  <select name="reason" id="" v-model="row.reschedule_reason">
                    <option value="rainout">Rainout</option>
                    <option value="double-booking">Double Booking</option>
                    <option value="uic-cancels">UIC Cancels</option>
                    <option value="umpire-cancels-on-site">
                      Umpire Cancels On Site ($)
                    </option>
                    <option value="away-team-cancels">Away Team Cancels</option>
                    <option value="home-team-cancels">Home Team Cancels</option>
                    <option value="other">Other</option>
                  </select>
                </td>
              </tr>
            </table>
            <div class="post-an-announcement">
              Do you want to post an announcement about this?
            </div>
            <div class="pills">
              <div
                :class="[
                  'pill',
                  { selected: rescheduleAnnouncementSwitch === true },
                ]"
                v-on:click="
                  rescheduleAnnouncementSwitch = true;
                  newAnnouncement.title = 'Games Rescheduled';
                "
              >
                Yes
              </div>
              <div
                :class="[
                  'pill',
                  { selected: rescheduleAnnouncementSwitch === false },
                ]"
                v-on:click="rescheduleAnnouncementSwitch = false"
              >
                No
              </div>
            </div>
            <div class="form-padding" v-if="rescheduleAnnouncementSwitch">
              <label for="">
                <span>Title</span>
                <input type="text" v-model="newAnnouncement.title" />
              </label>
              <label for="">
                <span>Announcement</span>
                <textarea v-model="newAnnouncement.description"></textarea>
              </label>
            </div>
            <a
              class="button button-purple"
              style="margin-left: 10px"
              v-on:click="submitRescheduledGames()"
            >
              Update Games
            </a>
          </div>
        </div>
      </div>
      <div class="upload-csv-modal-bg" v-if="uploadCSV">
        <div class="upload-csv-modal">
          <div class="step-one-three">
            <div class="csv-header">Step One - Upload</div>
            <div :class="['csv-content']">
              <p>
                <b>Note:</b> You do not need to use the CSV provided by us, you
                can use any major scheduling software's CSV.
              </p>
              <p>
                You will need your CSV to have a header row at the top. Not sure
                what this means?
                <a
                  href="https://www.techwalla.com/articles/how-to-make-a-header-row-in-a-csv-file"
                  >Check here.</a
                >
                Failure to having a header line will result in the first game in
                the schedule being dropped from the save file.
              </p>
              <div :class="['upload-area', { selected: file }]">
                <span v-if="!file"> Upload Your CSV Here </span>
                <span v-if="file"> File uploaded! </span>
                <input type="file" ref="file" v-on:change="handleCsvUpload()" />
              </div>
              <div v-if="!savingCSV">
                <a
                  class="button button-purple"
                  v-on:click="importYourSchedule()"
                  v-if="file"
                  >Import Your Schedule!</a
                >
              </div>
              <div v-if="savingCSV">
                <a class="button">Saving - Please Wait!</a>
              </div>
            </div>
            <div class="csv-header">
              Step Three - Confirm Teams &amp; Divisions
            </div>
            <div class="csv-content">
              <p>
                <b>Note:</b> Our system is only as good as your scheduler's
                ability to schedule all games, teams and divisions properly. If
                it doesn't look right over here, check your CSV for issues.
              </p>
              <div
                class="division-csv"
                v-for="(division, key) in parseFileData"
                :key="key"
              >
                <div class="division-header">{{ division.division }}</div>
                <div
                  class="team-name"
                  v-for="(team, team_key) in division.teams"
                  :key="team_key"
                >
                  {{ team.team }}
                </div>
              </div>
            </div>
          </div>
          <div class="step-two">
            <div class="csv-header">
              Step Two - Confirm Columns
              <span class="close-button" v-on:click="uploadCSV = !uploadCSV"
                ><i class="fas fa-times-circle"></i
              ></span>
            </div>
            <div class="csv-main-area">
              <table class="csv-columns">
                <thead>
                  <tr class="header-column">
                    <td colspan="9">
                      Select the column signifier in the dropdown assigned to
                      each column.
                    </td>
                  </tr>
                </thead>
                <tr class="signifier-row" v-if="fileData">
                  <td v-for="(column, index) in fileData[0]" :key="index">
                    <select class="csv-select" v-model="fileData[0][index]">
                      <option value="ignore_this_column">
                        -- Ignore This Column --
                      </option>
                      <option value="division_name">Division Name</option>
                      <option value="park_name">Park Name</option>
                      <option value="diamond_signifier">
                        Diamond Signifier
                      </option>
                      <option value="start_date">Start Date</option>
                      <option value="start_time">Start Time</option>
                      <option value="end_date">End Date</option>
                      <option value="end_time">End Time</option>
                      <option value="away_team">Team #1 (Away)</option>
                      <option value="home_team">Team #2 (Home)</option>
                    </select>
                  </td>
                </tr>
                <tr
                  class="regular-content-row"
                  v-for="(row, key) in fileData"
                  :key="key"
                >
                  <td v-for="(column, col_key) in row" :key="col_key">
                    {{ column }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="report-the-score-modal-bg" v-if="reportScoreOpen">
        <div class="report-the-score-modal">
          <div class="report-the-score-modal-header">
            Report the Score
            <span class="close-button" v-on:click="closeReportTheScore()"
              ><i class="fas fa-times-circle"></i
            ></span>
          </div>
          <div class="login-form">
            <div class="away-team-row">
              <div class="team-column">
                <span v-if="reportScoreInputs.away_team">{{
                  reportScoreInputs.away_team.name
                }}</span>
                <span v-if="!reportScoreInputs.away_team">{{
                  reportScoreInputs.away_team_freetext
                }}</span>
              </div>
              <div class="team-score">
                <input
                  type="text"
                  v-model="reportScoreInputs.away_score"
                  name="away_score"
                  placeholder="0"
                />
              </div>
            </div>
            <div class="home-team-row">
              <div class="team-column">
                <span v-if="reportScoreInputs.home_team">{{
                  reportScoreInputs.home_team.name
                }}</span>
                <span v-if="!reportScoreInputs.home_team">{{
                  reportScoreInputs.home_team_freetext
                }}</span>
              </div>
              <div class="team-score">
                <input
                  type="text"
                  v-model="reportScoreInputs.home_score"
                  name="home_score"
                  placeholder="0"
                />
              </div>
            </div>
            <div style="padding: 10px 30px" v-if="!computedAdmin">
              <label>
                <span>Enter Your Team Shortcode</span>
                <input
                  type="text"
                  v-model="reportScoreInputs.shortcode"
                  name="shortcode"
                  placeholder="This is to verify you're allowed to report this score."
                />
              </label>
            </div>
            <div style="text-align: center">
              <div
                class="button button-green"
                v-on:click="saveReportTheScore()"
                v-if="!reportingScore"
              >
                Submit Score
              </div>
              <div class="button button-green" v-if="reportingScore">
                Please Wait ...
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dashboard-current-window" v-if="!loading">
        Loading please wait!
      </div>
      <div :class="['dashboard-current-window']" v-if="loading">
        <div :class="['tool-bar', { mobile: isMobile }]" v-if="computedAdmin">
          <div class="inner-scroll">
            <a
              class="button"
              v-if="games.length > 0"
              v-on:click="addGameOpen = true"
            >
              <i class="fas fa-plus"></i> Add a Game
            </a>
            <a
              class="button button-purple"
              v-on:click="postponeGameOpen = !postponeGameOpen"
              v-if="selectedGames.length > 0"
            >
              <i class="fas fa-clock"></i> ({{ selectedGames.length }}) Postpone
              Games
            </a>

            <a
              class="button button-blue"
              v-on:click="rescheduleGameOpen = !rescheduleGameOpen"
              v-if="selectedGames.length > 0"
            >
              <i class="fas fa-calendar-alt"></i> ({{ selectedGames.length }})
              Reschedule Games
            </a>
            <a
              class="button button-red"
              v-on:click="deleteGames()"
              v-if="selectedGames.length > 0"
            >
              <i class="fas fa-trash"></i> ({{ selectedGames.length }}) Delete
              Games
            </a>
            <a
              href="https://docs.google.com/spreadsheets/d/175XuML3_rMNvE0aQXGYonChR9EWp72l175HX2nQIt6E/edit?usp=sharing"
              class="button button-green"
              target="_blank"
              v-if="selectedGames.length === 0"
            >
              <i class="fas fa-download"></i> Grab Schedule Template
            </a>
            <a
              class="button button-purple"
              v-on:click="uploadCSV = !uploadCSV"
              v-if="selectedGames.length === 0"
            >
              <i class="fas fa-upload"></i> Upload Your Schedule CSV
            </a>
            <a
              class="button button-blue"
              @click="downloadSchedule()"
              v-if="selectedGames.length === 0 && games.length > 0"
            >
              <i class="fas fa-download"></i> Download Schedule as CSV
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <div style="margin: 0 10px">
              <div class="step" style="margin-top: 0px">
                <div
                  class="step-header"
                  v-on:click="filterSwitch = !filterSwitch"
                >
                  Filters
                  <span style="float: right">
                    <i class="fas fa-caret-down" v-if="filterSwitch"></i>
                    <i class="fas fa-caret-up" v-if="!filterSwitch"></i>
                  </span>
                </div>
                <div
                  class="step-content"
                  v-if="filterSwitch"
                  style="padding: 0; margin-top: 0.5rem"
                >
                  <div class="step" style="margin-top: 0px" v-if="!isMobile">
                    <div
                      class="step-header"
                      v-on:click="chooseLayoutSwitch = !chooseLayoutSwitch"
                    >
                      Choose Layout
                      <span style="float: right">
                        <i
                          class="fas fa-caret-down"
                          v-if="chooseLayoutSwitch"
                        ></i>
                        <i
                          class="fas fa-caret-up"
                          v-if="!chooseLayoutSwitch"
                        ></i>
                      </span>
                    </div>
                    <div class="step-content" v-if="chooseLayoutSwitch">
                      <div
                        :class="[
                          'sidebar-choice',
                          { selected: dynamicOrTable === 'dynamic' },
                        ]"
                        v-on:click="changeLayout('dynamic')"
                      >
                        Dynamic
                      </div>
                      <div
                        :class="[
                          'sidebar-choice',
                          { selected: dynamicOrTable === 'table' },
                        ]"
                        v-on:click="changeLayout('table')"
                      >
                        Table
                      </div>
                    </div>
                  </div>
                  <div class="step" style="margin-top: 0px">
                    <div
                      class="step-header"
                      v-on:click="
                        hideReportedGameSwitch = !hideReportedGameSwitch
                      "
                    >
                      Reported Games
                      <span style="float: right">
                        <i
                          class="fas fa-caret-down"
                          v-if="hideReportedGameSwitch"
                        ></i>
                        <i
                          class="fas fa-caret-up"
                          v-if="!hideReportedGameSwitch"
                        ></i>
                      </span>
                    </div>
                    <div class="step-content" v-if="hideReportedGameSwitch">
                      <div
                        :class="[
                          'sidebar-choice',
                          { selected: showReportedGames === true },
                        ]"
                        v-on:click="toggleShowReportedGames(true)"
                      >
                        Show
                      </div>
                      <div
                        :class="[
                          'sidebar-choice',
                          { selected: showReportedGames === false },
                        ]"
                        v-on:click="toggleShowReportedGames(false)"
                      >
                        Hide
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <div
                      class="step-header"
                      v-on:click="
                        filterByDivisionSwitch = !filterByDivisionSwitch
                      "
                    >
                      Filter By Division
                      <span style="float: right">
                        <i
                          class="fas fa-caret-down"
                          v-if="filterByDivisionSwitch"
                        ></i>
                        <i
                          class="fas fa-caret-up"
                          v-if="!filterByDivisionSwitch"
                        ></i>
                      </span>
                    </div>
                    <div class="step-content" v-if="filterByDivisionSwitch">
                      <div
                        :class="[
                          'sidebar-choice',
                          { selected: selectedDivisionFilter === null },
                        ]"
                        v-on:click="
                          this.$router.replace({
                            query: { ...this.$route.query, division: 'all' },
                          })
                        "
                      >
                        All Divisions
                      </div>
                      <div
                        :class="[
                          'sidebar-choice',
                          { selected: division === selectedDivisionFilter },
                        ]"
                        v-for="(division, key) in keyFilters.division_id"
                        v-on:click="
                          this.$router.replace({
                            query: { ...this.$route.query, division },
                          })
                        "
                        :key="key"
                      >
                        {{ division }}
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <div
                      class="step-header"
                      v-on:click="
                        filterByLocationSwitch = !filterByLocationSwitch
                      "
                    >
                      Filter By Location
                      <span style="float: right">
                        <i
                          class="fas fa-caret-down"
                          v-if="filterByLocationSwitch"
                        ></i>
                        <i
                          class="fas fa-caret-up"
                          v-if="!filterByLocationSwitch"
                        ></i>
                      </span>
                    </div>
                    <div class="step-content" v-if="filterByLocationSwitch">
                      <div
                        :class="[
                          'sidebar-choice',
                          { selected: selectedLocationFilter === null },
                        ]"
                        v-on:click="
                          this.$router.replace({
                            query: { ...this.$route.query, location: 'all' },
                          })
                        "
                      >
                        All Locations
                      </div>
                      <div
                        :class="[
                          'sidebar-choice',
                          { selected: location === selectedLocationFilter },
                        ]"
                        v-for="(location, key) in keyFilters.park_name"
                        v-on:click="
                          this.$router.replace({
                            query: { ...this.$route.query, location },
                          })
                        "
                        :key="key"
                      >
                        {{ location }}
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <div
                      class="step-header"
                      v-on:click="filterByTeamSwitch = !filterByTeamSwitch"
                    >
                      Filter By Team
                      <span style="float: right">
                        <i
                          class="fas fa-caret-down"
                          v-if="filterByTeamSwitch"
                        ></i>
                        <i
                          class="fas fa-caret-up"
                          v-if="!filterByTeamSwitch"
                        ></i>
                      </span>
                    </div>
                    <div class="step-content" v-if="filterByTeamSwitch">
                      <div
                        :class="[
                          'sidebar-choice',
                          { selected: selectedTeamFilter === null },
                        ]"
                        v-on:click="
                          this.$router.replace({
                            query: { ...this.$route.query, team: 'all' },
                          })
                        "
                      >
                        All Teams
                      </div>
                      <div
                        :class="[
                          'sidebar-choice',
                          { selected: team.id == selectedTeamFilter },
                        ]"
                        v-for="(team, key) in teams"
                        v-on:click="
                          this.$router.replace({
                            query: { ...this.$route.query, team: team.id },
                          })
                        "
                        :key="key"
                      >
                        {{ team.name }}
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <div
                      class="step-header"
                      v-on:click="filterByMonthSwitch = !filterByMonthSwitch"
                    >
                      Filter By Month
                      <span style="float: right">
                        <i
                          class="fas fa-caret-down"
                          v-if="filterByMonthSwitch"
                        ></i>
                        <i
                          class="fas fa-caret-up"
                          v-if="!filterByMonthSwitch"
                        ></i>
                      </span>
                    </div>
                    <div class="step-content" v-if="filterByMonthSwitch">
                      <div
                        :class="[
                          'sidebar-choice',
                          { selected: selectedMonthFilter === null },
                        ]"
                        v-on:click="
                          this.$router.replace({
                            query: {
                              ...this.$route.query,
                              month: 'all',
                              datetime: 'all',
                            },
                          })
                        "
                      >
                        All Months
                      </div>
                      <div
                        :class="[
                          'sidebar-choice',
                          { selected: month == selectedMonthFilter },
                        ]"
                        v-for="(month, key) in keyFilters.month"
                        v-on:click="
                          this.$router.replace({
                            query: {
                              ...this.$route.query,
                              month: month,
                              datetime: 'all',
                            },
                          })
                        "
                        :key="key"
                      >
                        {{ month }}
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <div
                      class="step-header"
                      v-on:click="filterByDaySwitch = !filterByDaySwitch"
                    >
                      Filter By Day
                      <span style="float: right">
                        <i
                          class="fas fa-caret-down"
                          v-if="filterByDaySwitch"
                        ></i>
                        <i
                          class="fas fa-caret-up"
                          v-if="!filterByDaySwitch"
                        ></i>
                      </span>
                    </div>
                    <div class="step-content" v-if="filterByDaySwitch">
                      <div
                        :class="[
                          'sidebar-choice',
                          { selected: selectedDayFilter === null },
                        ]"
                        v-on:click="
                          this.$router.replace({
                            query: {
                              ...this.$route.query,
                              day: 'all',
                              datetime: 'all',
                            },
                          })
                        "
                      >
                        All Days
                      </div>
                      <div
                        :class="[
                          'sidebar-choice',
                          { selected: day == selectedDayFilter },
                        ]"
                        v-for="(day, key) in keyFilters.day"
                        v-on:click="
                          this.$router.replace({
                            query: {
                              ...this.$route.query,
                              day: day,
                              datetime: 'all',
                            },
                          })
                        "
                        :key="key"
                      >
                        {{ day }}
                      </div>
                    </div>
                  </div>
                  <div class="step">
                    <div
                      class="step-header"
                      v-on:click="
                        filterByTimeslotSwitch = !filterByTimeslotSwitch
                      "
                    >
                      Filter By Timeslot
                      <span style="float: right">
                        <i
                          class="fas fa-caret-down"
                          v-if="filterByTimeslotSwitch"
                        ></i>
                        <i
                          class="fas fa-caret-up"
                          v-if="!filterByTimeslotSwitch"
                        ></i>
                      </span>
                    </div>
                    <div class="step-content" v-if="filterByTimeslotSwitch">
                      <div
                        :class="[
                          'sidebar-choice',
                          { selected: selectedTimeslotFilter === null },
                        ]"
                        v-on:click="
                          this.$router.replace({
                            query: {
                              ...this.$route.query,
                              time: 'all',
                              datetime: 'all',
                            },
                          })
                        "
                      >
                        All Timeslots
                      </div>
                      <div
                        :class="[
                          'sidebar-choice',
                          { selected: time == selectedTimeslotFilter },
                        ]"
                        v-for="(time, key) in keyFilters.start_time"
                        v-on:click="
                          this.$router.replace({
                            query: {
                              ...this.$route.query,
                              time: time,
                              datetime: 'all',
                            },
                          })
                        "
                        :key="key"
                      >
                        {{ moment(time, "hh:mm:ss").format("h:mm A") }}
                        <div
                          v-if="
                            selectedTimeslotFilter == time &&
                            filterDateByTime.length > 0 &&
                            !isMobile
                          "
                        >
                          <span style="margin-bottom: 0.5rem; display: block">
                          </span>
                          <div
                            :style="{
                              color:
                                selectedDateTimeFilter === null
                                  ? 'white'
                                  : 'black',
                            }"
                            :class="[
                              'sidebar-choice',
                              { selected: selectedDateTimeFilter === null },
                            ]"
                            v-on:click.stop="
                              this.$router.replace({
                                query: {
                                  ...this.$route.query,
                                  datetime: 'all',
                                },
                              })
                            "
                          >
                            All Dates
                          </div>
                          <div
                            :style="{
                              color:
                                selectedDateTimeFilter === datetime
                                  ? 'white'
                                  : 'black',
                            }"
                            :class="[
                              'sidebar-choice',
                              { selected: datetime == selectedDateTimeFilter },
                            ]"
                            v-for="(datetime, key) in filterDateByTime"
                            v-on:click.stop="
                              this.$router.replace({
                                query: {
                                  ...this.$route.query,
                                  datetime: datetime,
                                },
                              })
                            "
                            :key="key"
                          >
                            {{
                              moment(datetime, "YYYY-MM-DD hh:mm:ss").format(
                                "MMMM Do"
                              )
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="hide-on-mobile">
                <Adsense
                  data-ad-client="ca-pub-7578943240214333"
                  data-ad-slot="2396693351"
                >
                </Adsense>
              </div>
            </div>
          </div>
          <div class="col-md-10">
            <div class="help-area" v-if="games.length === 0">
              <div class="step-content">
                <p>
                  To get started, download the Schedule CSV! We make things easy
                  - upload your schedule and our system will figure out the
                  teams and divisions based on it.
                </p>
              </div>
            </div>

            <div class="schedule-view" v-if="dynamicOrTable === 'table'">
              <div
                class="division-row"
                v-for="(date, key) in computedSchedule"
                style="max-width: 100%"
                :key="key"
              >
                <div
                  class="division-header"
                  :id="date.date === currentDate ? 'table-current-date' : ''"
                  :ref="
                    (el) => {
                      date.date === currentDate ? scrollToCurrentDate(el) : '';
                    }
                  "
                >
                  {{ moment(date.date, "YYYY-MM-DD").format("dddd MMMM Do") }}
                </div>
                <div class="overflow-table-scroll">
                  <table class="standings-table">
                    <tr class="table-header">
                      <td>Time</td>
                      <td>Diamond</td>
                      <td>Division</td>
                      <td style="width: 225px">Away Team</td>
                      <td>Score</td>
                      <td>Score</td>
                      <td style="width: 225px">Home Team</td>
                      <td></td>
                    </tr>
                    <tr v-for="(game, game_key) in date.games" :key="game_key">
                      <td>
                        {{
                          moment(game.start_time, "hh:mm:ss").format("h:mm A")
                        }}
                        -
                        {{ moment(game.end_time, "hh:mm:ss").format("h:mm A") }}
                      </td>
                      <td>{{ game.park_name }} {{ game.diamond_signifier }}</td>
                      <td>{{ game.division_id }}</td>
                      <td>
                        <span v-if="game.awayTeam">
                          <marquee
                            behavior="scroll"
                            direction="left"
                            onmouseout="this.start();"
                            onmouseover="this.stop();"
                            v-if="game.awayTeam.name.length > 35"
                          >
                            {{ game.awayTeam.name }}
                          </marquee>
                          <span v-else>{{ game.awayTeam.name }}</span>
                        </span>
                        <span v-if="!game.awayTeam">
                          <marquee
                            behavior="scroll"
                            direction="left"
                            onmouseout="this.start();"
                            onmouseover="this.stop();"
                            v-if="game.away_team_freetext.length > 35"
                          >
                            {{ game.away_team_freetext }}
                          </marquee>
                          <span v-else>
                            {{ game.away_team_freetext }}
                          </span>
                        </span>
                      </td>
                      <td>{{ game.away_team_score }}</td>
                      <td>{{ game.home_team_score }}</td>
                      <td>
                        <span v-if="game.homeTeam">
                          <marquee
                            behavior="scroll"
                            direction="left"
                            onmouseout="this.start();"
                            onmouseover="this.stop();"
                            v-if="game.homeTeam.name.length > 35"
                          >
                            {{ game.homeTeam.name }}
                          </marquee>
                          <span v-else>{{ game.homeTeam.name }}</span>
                        </span>
                        <span v-if="!game.homeTeam">
                          <marquee
                            behavior="scroll"
                            direction="left"
                            onmouseout="this.start();"
                            onmouseover="this.stop();"
                            v-if="game.home_team_freetext.length > 35"
                          >
                            {{ game.home_team_freetext }}
                          </marquee>
                          <span v-else>
                            {{ game.home_team_freetext }}
                          </span>
                        </span>
                      </td>
                      <td>
                        <div
                          class="button button-purple"
                          v-if="game.game_status === 'active'"
                        >
                          <div
                            v-if="game.score_reported === 0"
                            v-on:click="reportTheScore(game)"
                          >
                            Report This Score
                          </div>
                          <div
                            v-if="game.score_reported === 1 && computedAdmin"
                            v-on:click="editTheScore(game)"
                          >
                            Edit This Score
                          </div>
                          <div
                            v-if="game.score_reported === 1 && !computedAdmin"
                            style="text-transform: capitalize; font-weight: 500"
                          >
                            Reported
                          </div>
                        </div>
                        <div
                          class="button button-blue"
                          v-if="game.game_status === 'postponed'"
                        >
                          <div>Postponed - Please Reschedule</div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="schedule-view" v-if="dynamicOrTable === 'dynamic'">
              <div
                class="schedule-column"
                style="margin-bottom: 2rem"
                v-for="(date, key) in computedSchedule"
                :key="key"
              >
                <div
                  class="schedule-date-header"
                  :ref="
                    (el) => {
                      date.date === currentDate ? scrollToCurrentDate(el) : '';
                    }
                  "
                >
                  {{ moment(date.date, "YYYY-MM-DD").format("dddd MMMM Do") }}
                </div>
                <div
                  :class="['game-box', { addMargin: computedAdmin }]"
                  v-for="game in date.games"
                  :key="game.id"
                >
                  <label
                    :class="[
                      'checkbox-box',
                      { selected: checkSelectedGame(game) },
                    ]"
                    v-if="computedAdmin"
                  >
                    <input
                      type="checkbox"
                      v-model="selectedGames"
                      :value="game"
                    />
                    <i class="far fa-check-square"></i>
                  </label>
                  <div class="date-time-row">
                    <div class="date-time-column" style="flex: 2">
                      {{ moment(game.start_time, "hh:mm:ss").format("h:mm A") }}
                    </div>
                    <div
                      class="date-time-column"
                      style="flex: 3; text-align: center"
                    >
                      {{ game.division_id }}
                    </div>
                    <div
                      class="date-time-column"
                      style="flex: 3; text-align: center"
                    >
                      {{ game.park_name }} {{ game.diamond_signifier }}
                    </div>
                  </div>
                  <div class="game-teams-rows">
                    <div class="game-team-row">
                      <div class="game-team-name">
                        <span v-if="game.awayTeam">
                          <marquee
                            behavior="scroll"
                            direction="left"
                            onmouseout="this.start();"
                            onmouseover="this.stop();"
                            v-if="game.awayTeam.name.length > 35"
                          >
                            {{ game.awayTeam.name }}
                          </marquee>
                          <span v-else>{{ game.awayTeam.name }}</span>
                        </span>
                        <span v-if="!game.awayTeam">
                          <marquee
                            behavior="scroll"
                            direction="left"
                            onmouseout="this.start();"
                            onmouseover="this.stop();"
                            v-if="game.away_team_freetext.length > 35"
                          >
                            {{ game.away_team_freetext }}
                          </marquee>
                          <span v-else>{{ game.away_team_freetext }}</span>
                        </span>
                      </div>
                      <div class="game-team-report-score">
                        {{ game.away_team_score }}
                      </div>
                    </div>
                    <div class="game-team-row second-row">
                      <div class="game-team-name">
                        <span v-if="event.show_home_away === 1">at </span>
                        <span v-if="game.homeTeam">
                          <marquee
                            behavior="scroll"
                            direction="left"
                            onmouseout="this.start();"
                            onmouseover="this.stop();"
                            v-if="game.homeTeam.name.length > 35"
                          >
                            {{ game.homeTeam.name }}
                          </marquee>
                          <span v-else>{{ game.homeTeam.name }}</span>
                        </span>
                        <span v-if="!game.homeTeam">
                          <marquee
                            behavior="scroll"
                            direction="left"
                            onmouseout="this.start();"
                            onmouseover="this.stop();"
                            v-if="game.home_team_freetext.length > 35"
                          >
                            {{ game.home_team_freetext }}
                          </marquee>
                          <span v-else>{{ game.home_team_freetext }}</span>
                        </span>
                      </div>
                      <div class="game-team-report-score">
                        {{ game.home_team_score }}
                      </div>
                    </div>
                  </div>
                  <div v-if="game.game_status === 'active'">
                    <div
                      class="button button-purple"
                      v-if="
                        game.score_reported === 0 &&
                        (game.awayTeam || game.homeTeam)
                      "
                      v-on:click="reportTheScore(game)"
                    >
                      Report This Score
                    </div>
                    <div
                      class="button button-red"
                      v-if="game.score_reported === 1 && computedAdmin"
                      v-on:click="editTheScore(game)"
                    >
                      Edit This Score
                    </div>
                    <div
                      class="button"
                      v-if="game.score_reported === 1 && !computedAdmin"
                      style="text-transform: capitalize; font-weight: 700"
                    >
                      {{ game.awayTeam.name }} ({{ game.awayTeam.wins }}-{{
                        game.awayTeam.losses
                      }}-{{ game.awayTeam.ties }}) -
                      {{ game.homeTeam.name }} ({{ game.homeTeam.wins }}-{{
                        game.homeTeam.losses
                      }}-{{ game.homeTeam.ties }})
                    </div>
                  </div>
                  <div
                    class="button button-blue"
                    v-if="game.game_status === 'postponed'"
                  >
                    <div>Postponed - Please Reschedule</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Adsense
          data-ad-client="ca-pub-7578943240214333"
          data-ad-slot="9145934530"
        >
        </Adsense>
        <div class="current-date" v-if="isMobile">
          <div style="display: flex; justify-content: space-between">
            <span
              v-if="findDateIndex() > 0"
              style="font-size: 30px"
              class="fas fa-caret-left"
              @click="prevDate()"
            ></span>
            <span v-else></span>
            <p @click="datePickerModal = true">
              {{ moment(currentDate).format("dddd MMMM Do") }}
            </p>
            <span
              v-if="findDateIndex() < this.availableDates.length - 1"
              style="font-size: 30px"
              class="fas fa-caret-right"
              @click="nextDate()"
            ></span>
            <span v-else></span>
          </div>
        </div>

        <div class="add-game-modal-bg" v-if="datePickerModal">
          <div class="add-game-modal">
            <div class="add-game-modal-header">
              Select Date
              <span class="close-button" v-on:click="datePickerModal = false"
                ><i class="fas fa-times-circle"></i
              ></span>
            </div>
            <ul style="max-height: 400px; overflow: auto">
              <li
                v-for="date in availableDates"
                :key="date"
                style="padding: 1rem 1.5rem; display: flex; align-items: center"
                :style="{ color: date === currentDate ? 'blue' : 'black' }"
              >
                <span @click="setCurrentDate(date)">
                  {{ moment(date).format("dddd MMMM Do") }}
                </span>
                <span v-if="date === currentDate" style="margin-left: 0.5rem"
                  ><i class="fas fa-check"></i
                ></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </span>
  </span>
</template>

<script>
import moment from "moment";
import tz from "moment-timezone";
import { mapState, mapActions } from "vuex";
var papa = require("papaparse");
import _ from "lodash";
var ordinal = require("ordinal");
var mobile = require("is-mobile");

export default {
  name: "SchedulePage",
  // To use props, they must be declared
  props: {
    event: Object,
    computedAdmin: Boolean,
    eventId: Number,
    teams: Array,
  },
  data: function () {
    return {
      dynamicOrTable: "dynamic",
      scrolled: false,
      reportingScore: false,
      showReportedGames: true,
      reportScoreOpen: false,
      addGameOpen: false,
      loading: false,
      games: [],
      currentDate: null,
      currentDateCache: null,
      availableDates: [],
      datePickerModal: false,
      postponeGameOpen: false,
      rescheduleGameOpen: false,
      file: null,
      fileData: null,
      uploadCSV: false,
      savingCSV: false,
      selectedGames: [],
      filterSwitch: true,
      chooseLayoutSwitch: false,
      hideReportedGameSwitch: false,
      filterByDivisionSwitch: false,
      filterByLocationSwitch: false,
      filterByTeamSwitch: false,
      filterByTimeslotSwitch: false,
      filterByDaySwitch: false,
      filterByMonthSwitch: false,
      rescheduleAnnouncementSwitch: false,
      postponeAnnouncementSwitch: false,
      reasonList: [
        "Weather Related",
        "Away Team Cancels",
        "Home Team Cancels",
        "Mistake",
        "Double Booking",
        "Other",
      ],
      selectedDivisionFilter: null,
      selectedLocationFilter: null,
      selectedTeamFilter: null,
      selectedTimeslotFilter: null,
      selectedDateTimeFilter: null,
      selectedDayFilter: null,
      selectedMonthFilter: null,
      newAnnouncement: {
        title: null,
        description: null,
      },
      reportScoreInputs: {
        game: null,
        away_team: null,
        away_team_freetext: null,
        away_score: null,
        home_team: null,
        home_team_freetext: null,
        home_score: null,
        shortcode: null,
        edit: false,
      },
      addGameRows: [
        {
          game: null,
          mode: "season",
          start_date: null,
          start_time: null,
          end_time: null,
          park_name: null,
          diamond_signifier: null,
          division_id: null,
          select_away_team: null,
          select_home_team: null,
          game_id: null,
          edit: false,
        },
      ],
    };
  },
  computed: {
    ...mapState(["user", "clientTimezone"]),
    isMobile() {
      return mobile();
    },
    keyFilters: function () {
      var vm = this;

      var object = {};

      var filters = [
        "division_id",
        "park_name",
        "start_time",
        "datetime",
        "day",
        "month",
      ];

      filters.map((filter) => {
        var keyBy = _.keyBy(vm.games, filter);
        var keys = Object.keys(keyBy);
        object[filter] = keys;
      });
      // object["month"] = Object.keys(_.keyBy(vm.games, "month"));
      console.log(object);
      return object;
    },
    filterDateByTime() {
      const time = this.selectedTimeslotFilter;
      const month = this.selectedMonthFilter;
      const day = this.selectedDayFilter;
      const datetime = this.keyFilters.datetime.filter((datetime) => {
        if (month) {
          if (
            this.moment(datetime, "YYYY-MM-DD hh:mm:ss").format("MMMM") !==
            month
          ) {
            return false;
          }
        }
        if (day) {
          if (
            this.moment(datetime, "YYYY-MM-DD hh:mm:ss").format("dddd") !== day
          ) {
            return false;
          }
        }
        return (
          this.moment(datetime, "YYYY-MM-DD hh:mm:ss").format("HH:mm:ss") ===
          time
        );
      });
      return datetime;
    },
    filterByDates: function () {
      var games = this.games;

      var keyBy = _.keyBy(games, function (game) {
        return game.start_date;
      });
      var keys = Object.keys(keyBy);

      keys = _.orderBy(keys, [], "asc");

      return keys;
    },
    computedDivisions: function () {
      // take all the teams, pull divisions via a grouping
      var vm = this;
      var teams = vm.teams;

      var group = _.groupBy(teams, "division_name");
      var divisions = Object.keys(group);

      var array = [];

      var i = 0;

      for (i; i < divisions.length; i++) {
        var division = divisions[i];

        array.push({
          division_name: division,
          teams: [],
        });
      }

      var o = 0;

      for (o; o < array.length; o++) {
        division = array[o];

        var filtered_teams = _.filter(teams, function (team) {
          return team.division_name === division.division_name;
        });

        array[o].teams = filtered_teams;
      }

      console.log("computedDivisions", array);

      return array;
    },
    filteredGames() {
      const vm = this;
      var filter_items = {
        division_id: vm.selectedDivisionFilter,
        park_name: vm.selectedLocationFilter,
        team: vm.selectedTeamFilter,
        start_time: vm.selectedTimeslotFilter,
        datetime: vm.selectedDateTimeFilter,
        day: vm.selectedDayFilter,
        month: vm.selectedMonthFilter,
      };

      return this.games.filter((game) => {
        var check = false;
        if (this.isMobile) {
          if (game.start_date !== this.currentDate) {
            return false;
          }
        }
        if (this.showReportedGames === false && game.score_reported == 1) {
          return false;
        }

        for (const [key, item] of Object.entries(filter_items)) {
          var filter_item = filter_items[key];

          if (filter_item) {
            if (key === "team") {
              if (
                game["away_team_id"] != filter_item &&
                game["home_team_id"] != filter_item
              ) {
                return false;
              }
            } else {
              if (game[key] != filter_item) {
                return false;
              }
            }
          }
        }
        return true;
      });
    },
    computedSchedule: function () {
      // check if dynamic or table
      var vm = this;
      // var games = this.games;
      const games = this.filteredGames;

      var array = [];

      games.map((game) => {
        game.new_date = this.moment(game.start_date).format("YYYY-MM-DD");
      });

      var keys = vm.filterByDates;

      keys.map((key) => {
        var item = {};
        var filtered_games = games.filter((game) => {
          return game.start_date === key;
        });
        if (filtered_games.length > 0) {
          item.date = key;
          item.games = filtered_games;
          array.push(item);
        }
      });
      return array;
    },
    parseFileData: function () {
      var vm = this;

      if (this.fileData) {
        var division_column = null;
        var team_name_one_column = null;
        var team_name_two_column = null;

        var o = 0;
        for (o; o < vm.fileData[0].length; o++) {
          var column = vm.fileData[0][o];
          if (column === "division_name") {
            division_column = o;
          }
          if (column === "away_team") {
            team_name_one_column = o;
          }
          if (column === "home_team") {
            team_name_two_column = o;
          }
        }

        var divisions_all = [];
        var teams_all = [];
        var teams_with_divisions = [];

        var i = 0;

        for (i; i < vm.fileData.length; i++) {
          var row = vm.fileData[i];

          var z = 0;

          if (i > 0) {
            for (z; z < row.length; z++) {
              if (division_column === z) {
                divisions_all.push(row[z]);
              }
              if (team_name_one_column === z) {
                teams_all.push(row[z]);
              }
              if (team_name_two_column === z) {
                teams_all.push(row[z]);
              }
            }
          }
        }

        var unique_divisions = _.uniqBy(divisions_all);
        var unique_teams = _.uniqBy(teams_all);

        // find each team their division
        var s = 0;
        for (s; s < unique_teams.length; s++) {
          var team = unique_teams[s];

          var e = 0;
          var filter = _.find(vm.fileData, function (fileRow, index) {
            if (fileRow[team_name_one_column] === team) {
              return true;
            }
            if (fileRow[team_name_two_column] === team) {
              return true;
            }
            return false;
          });

          var division = filter[division_column];

          teams_with_divisions.push({
            team: team,
            division: division,
          });
        }

        // now set up the final array, the way you had it in quickstart

        var u = 0;

        var finalized_divisions = [];

        for (u; u < unique_divisions.length; u++) {
          var div = unique_divisions[u];
          division = {};
          division.division = div;
          var teams = _.filter(teams_with_divisions, function (team) {
            return team.division === div;
          });

          var t = 0;
          for (t; t < teams.length; t++) {
            teams[t].name = null;
            teams[t].password = null;
            teams[t].coach_name = null;
            teams[t].coach_email = null;
            teams[t].secondary_name = null;
            teams[t].secondary_email = null;
          }

          division.teams = teams;

          finalized_divisions.push(division);
        }

        return finalized_divisions;
      }
      return [];
    },
  },
  mounted: async function () {
    if (mobile()) {
      this.dynamicOrTable = "dynamic";
      this.filterSwitch = false;
    } else if (localStorage.getItem("scheduleLayout")) {
      this.dynamicOrTable = localStorage.getItem("scheduleLayout");
    }
    if (localStorage.getItem("showReportedGames")) {
      this.showReportedGames =
        localStorage.getItem("showReportedGames") === "true";
    }
    if (localStorage.getItem("shortcode")) {
      this.reportScoreInputs.shortcode = localStorage.getItem("shortcode");
    }
    this.getGames().then(() => {
      this.getUrlQueryFilter();
    });
  },
  watch: {
    $route(value) {
      this.getUrlQueryFilter();
    },
    addGameOpen(value) {
      if (value === true) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    postponeGameOpen(value) {
      if (value === true) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    rescheduleGameOpen(value) {
      if (value === true) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    datePickerModal(value) {
      if (value === true) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
  methods: {
    ...mapActions(["setNotification"]),
    ordinal(place) {
      return ordinal(place);
    },
    getDateWiseArray(gameArr) {
      const array = [];
      gameArr.map((game) => {
        game.new_date = moment(game.start_date).format("YYYY-MM-DD");
      });

      var keyBy = _.keyBy(gameArr, function (game) {
        return game.start_date;
      });
      var keys = Object.keys(keyBy);

      keys = _.orderBy(keys, [], "asc");

      keys.map((key) => {
        var item = {};
        var filtered_games = gameArr.filter((game) => {
          return game.start_date === key;
        });
        if (filtered_games.length > 0) {
          item.date = key;
          item.games = filtered_games;
          array.push(item);
        }
      });
      return array;
    },
    async findCurrentdate(games) {
      const array = this.getDateWiseArray(games);
      const dates = array.map((game) => game.date);
      console.log("dates", dates);

      let nearestDate;

      dates.forEach((date) => {
        let diff = Math.abs(moment(date).diff(moment(), "days"));

        if (nearestDate) {
          const nearestdiff = Math.abs(
            moment(nearestDate).diff(moment(), "days")
          );

          if (nearestdiff > diff) {
            nearestDate = date;
          }
        } else {
          nearestDate = date;
        }
      });
      console.log("nearest date", nearestDate);
      this.currentDate = nearestDate;
      this.currentDateCache = nearestDate;
      this.availableDates = dates;
    },
    scrollToCurrentDate(el) {
      if (!mobile() && !this.scrolled) {
        console.log("scrolling");
        this.$nextTick(() => {
          el.scrollIntoView();
          window.scrollBy(0, -150);
          this.scrolled = true;
        });
      }
    },
    findDateIndex() {
      let index = -1;
      for (let i = 0; i < this.availableDates.length; i++) {
        if (this.availableDates[i] === this.currentDate) {
          index = i;
          break;
        }
      }
      return index;
    },
    setCurrentDate(date) {
      this.currentDate = date;
      this.datePickerModal = false;
    },
    nextDate() {
      const currentIndex = this.findDateIndex();
      if (currentIndex < this.availableDates.length - 1) {
        this.currentDate = this.availableDates[currentIndex + 1];
      }
    },
    prevDate() {
      const currentIndex = this.findDateIndex();
      if (currentIndex > 0) {
        this.currentDate = this.availableDates[currentIndex - 1];
      }
    },
    async getGames() {
      let slug = this.$route.params.slug;
      let vm = this;
      vm.loading = false;
      this.$axios.get("/api/get-games/web/" + slug).then((results) => {
        vm.games = results.data.games;
        vm.games.forEach((game) => {
          game.datetime = game.start_date + " " + game.start_time;
          game.day = this.moment(game.start_date, "YYYY-MM-DD").format("dddd");
          game.month = this.moment(game.start_date, "YYYY-MM-DD").format(
            "MMMM"
          );
        });

        console.log("games", vm.games);
        vm.findCurrentdate(vm.games);
        vm.loading = true;
        if (this.$route.query.game) {
          var param = this.$route.query.game;
          var selected_game = vm.games.find((game) => {
            return param == game.id;
          });
          this.reportTheScore(selected_game);
        }
      });
    },
    getUrlQueryFilter() {
      if (this.$route.query.division) {
        if (this.$route.query.division === "all") {
          this.selectedDivisionFilter = null;
        } else {
          this.selectedDivisionFilter = this.$route.query.division;
          this.filterByDivisionSwitch = true;
        }
      }
      if (this.$route.query.location) {
        if (this.$route.query.location === "all") {
          this.selectedLocationFilter = null;
        } else {
          this.selectedLocationFilter = this.$route.query.location;
          this.filterByLocationSwitch = true;
        }
      }
      if (this.$route.query.team) {
        if (this.$route.query.team === "all") {
          this.selectedTeamFilter = null;
        } else {
          this.selectedTeamFilter = this.$route.query.team;
          this.filterByTeamSwitch = true;
        }
      }
      if (this.$route.query.datetime) {
        if (this.$route.query.datetime === "all") {
          this.selectedDateTimeFilter = null;
        } else {
          this.selectedDateTimeFilter = this.$route.query.datetime;
        }
      }
      if (this.$route.query.time) {
        if (this.$route.query.time === "all") {
          this.selectedTimeslotFilter = null;
          this.selectedDateTimeFilter = null;
        } else {
          this.selectedTimeslotFilter = this.$route.query.time;
          this.filterByTimeslotSwitch = true;
        }
      }
      if (this.$route.query.day) {
        if (this.$route.query.day === "all") {
          this.selectedDayFilter = null;
        } else {
          this.selectedDayFilter = this.$route.query.day;
          this.filterByDaySwitch = true;
        }
      }
      if (this.$route.query.month) {
        if (this.$route.query.month === "all") {
          this.selectedMonthFilter = null;
        } else {
          this.selectedMonthFilter = this.$route.query.month;
          this.filterByMonthSwitch = true;
        }
      }
    },
    moment(date, type = null) {
      return moment.tz(date, type, this.clientTimezone);
    },
    changeLayout(layout) {
      this.dynamicOrTable = layout;
      localStorage.setItem("scheduleLayout", layout);
    },
    toggleShowReportedGames(choice) {
      this.showReportedGames = choice;
      localStorage.setItem("showReportedGames", choice);
    },
    checkSelectedGame: function (data_game) {
      var vm = this;
      var filter = _.filter(vm.selectedGames, function (game) {
        return game.id === data_game.id;
      });

      if (filter.length > 0) {
        return true;
      }
      return false;
    },
    reportTheScore: function (game) {
      this.reportScoreInputs.game = game;
      game.away_team_score = null;
      this.reportScoreInputs.away_team = game.awayTeam;
      this.reportScoreInputs.away_team_freetext = game.away_team_freetext;
      game.home_team_score = null;
      this.reportScoreInputs.home_team = game.homeTeam;
      this.reportScoreInputs.home_team_freetext = game.home_team_freetext;
      this.reportScoreOpen = true;
      this.$router.replace({ query: { game: game.id } });
    },
    editTheScore: function (game) {
      this.reportScoreInputs.game = game;
      this.reportScoreInputs.away_score = game.away_team_score;
      this.reportScoreInputs.away_team = game.awayTeam;
      this.reportScoreInputs.away_team_freetext = game.away_team_freetext;
      this.reportScoreInputs.home_score = game.home_team_score;
      this.reportScoreInputs.home_team = game.homeTeam;
      this.reportScoreInputs.home_team_freetext = game.home_team_freetext;
      this.reportScoreInputs.edit = true;
      this.reportScoreOpen = true;
    },
    saveReportTheScore: function () {
      var vm = this;
      this.reportingScore = true;
      this.$axios
        .post(
          `/api/events/${vm.eventId}/games/${vm.reportScoreInputs.game.id}/report`,
          {
            home_score: vm.reportScoreInputs.home_score,
            away_score: vm.reportScoreInputs.away_score,
            shortcode: vm.reportScoreInputs.shortcode,
            edit: vm.reportScoreInputs.edit,
            user: { id: this.user?.id },
          }
        )
        .then(async (result) => {
          console.log("result", result);
          if (result.data.type === "success") {
            vm.reportScoreInputs.game = null;
            vm.reportScoreInputs.away_team = null;
            vm.reportScoreInputs.away_team_freetext = null;
            vm.reportScoreInputs.home_team = null;
            vm.reportScoreInputs.home_team_freetext = null;
            vm.reportScoreInputs.edit = false;
            vm.reportScoreOpen = false;
            vm.reportScoreInputs.home_score = 0;
            vm.reportScoreInputs.away_score = 0;
            // vm.event = result.data.event;
            localStorage.setItem("shortcode", vm.reportScoreInputs.shortcode);

            vm.$emit("set-notification", [
              "Score successfully reported.",
              "success",
            ]);

            let query = Object.assign({}, this.$route.query);
            delete query.game;
            this.$router.replace({ query });

            await this.getGames();
          } else {
            vm.$emit("set-notification", [
              "Something went wrong. Make sure you're using your correct shortcode. If this persists, please contact us.",
              "danger",
            ]);
          }
          this.reportingScore = false;
        })
        .catch((err) => {
          console.log(err);
          this.reportingScore = false;
        });
    },
    submitNewGames: function () {
      var vm = this;
      let flag = false;
      for (const row of vm.addGameRows) {
        console.log(
          this.moment(row.start_date, "YYYY/MM/DD").format("YYYY/MM/DD")
        );
        console.log(this.moment(row.start_time, "hh:mm:ss").format("hh:mm:ss"));
        console.log(this.moment(row.end_time, "hh:mm:ss").format("hh:mm:ss"));
        if (row.select_away_team === null) {
          this.setNotification(["Please Select away team", "danger"]);
          flag = true;
          break;
        }
        if (row.select_home_team === null) {
          this.setNotification(["Please Select home team", "danger"]);
          flag = true;
          break;
        }
        if (
          this.moment(row.start_date, "YYYY/MM/DD").format("YYYY-MM-DD") ===
          "Invalid date"
        ) {
          this.setNotification([
            "Invalid start date " + row.start_date,
            "danger",
          ]);
          flag = true;
          break;
        }
        if (
          this.moment(row.start_time, "hh:mm:ss").format("hh:mm:ss") ===
          "Invalid date"
        ) {
          this.setNotification([
            "Invalid start time " + row.start_time,
            "danger",
          ]);
          flag = true;
          break;
        }
        if (
          this.moment(row.end_time, "hh:mm:ss").format("hh:mm:ss") ===
          "Invalid date"
        ) {
          this.setNotification(["Invalid end time " + row.end_time, "danger"]);
          flag = true;
          break;
        }
      }
      if (flag === false) {
        this.$axios
          .post("/api/save-new-game", {
            event: vm.eventId,
            game_data: vm.addGameRows,
          })
          .then(async (result) => {
            if (result.data.type === "success") {
              // starting here
              // var i = 0;
              // for (i; i < result.data.games.length; i++) {
              //   var game = result.data.games[i];
              //   vm.games.push(game);
              // }
              vm.addGameRows = [
                {
                  game: null,
                  start_date: null,
                  start_time: null,
                  end_time: null,
                  park_name: null,
                  diamond_signifier: null,
                  division_id: null,
                  select_away_team: null,
                  select_home_team: null,
                  game_id: null,
                  edit: false,
                },
              ];
              vm.addGameOpen = false;
              await this.getGames();
              vm.$emit("set-notification", [
                "New game saved and added to the schedule!",
                "success",
              ]);
            } else {
              vm.$emit("set-notification", [
                "Something went wrong. If this persists, please contact us.",
                "danger",
              ]);
            }
          });
      }
    },
    rescheduleGame: function (game) {
      var vm = this;
      vm.submitNewGameData.game = game;
      vm.submitNewGameData.start_date = this.moment(game.start_date).format(
        "YYYY-MM-DD"
      );
      vm.submitNewGameData.start_time = game.start_time;
      vm.submitNewGameData.end_time = game.end_time;
      vm.submitNewGameData.park_name = game.park_name;
      vm.submitNewGameData.diamond_signifier = game.diamond_signifier;
      vm.submitNewGameData.division_id = game.division_id;
      vm.submitNewGameData.select_away_team = game.awayTeam.id;
      vm.submitNewGameData.select_home_team = game.homeTeam.id;
      vm.submitNewGameData.game_id = game.id;
      vm.submitNewGameData.edit = true;

      vm.addGameOpen = true;
    },
    addGameRow: function () {
      this.addGameRows.push({
        game: null,
        start_date: null,
        start_time: null,
        end_time: null,
        park_name: null,
        diamond_signifier: null,
        division_id: null,
        select_away_team: null,
        select_home_team: null,
        game_id: null,
        edit: false,
      });
    },
    downloadSchedule() {
      const games = [];
      this.filteredGames.forEach((game) => {
        const g = {};
        g.Date = this.moment(game.start_date, "YYYY-MM-DD").format(
          "MMMM Do YY"
        );
        g.Time = this.moment(game.start_time, "hh:mm:ss").format("h:mm A");
        g.Venue = game.park_name;
        if (game.diamond_signifier) {
          g.Venue = game.park_name + " - " + game.diamond_signifier;
        }
        g.Division = game.division_id;
        g.Home = game.homeTeam.name;
        g["Home Score"] = game.home_team_score;
        g["Away Score"] = game.away_team_score;
        g.Away = game.awayTeam.name;
        games.push(g);
      });
      const csv = papa.unparse(games);
      const title = this.event.name + " - Schedule";
      console.log(csv);
      const anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      anchor.target = "_blank";
      anchor.download = `${title}.csv`;
      anchor.click();
    },
    importYourSchedule: function () {
      // save-csv
      var vm = this;
      vm.savingCSV = true;
      var data = [...vm.fileData];
      var first_row = data.shift();

      this.$axios
        .post("/api/save-csv", {
          event: vm.eventId,
          divisions: vm.parseFileData,
          first_row: first_row,
          rows: data,
        })
        .then((result) => {
          if (result.data.type === "success") {
            // vm.event = result.data.event;
            vm.uploadCSV = false;

            vm.$emit("set-notification", [
              "You have successfully uploaded your schedule!",
              "success",
            ]);
            this.$router.go(0);
          } else {
            vm.$emit("set-notification", [
              "Something went wrong. If this persists, please contact us.",
              "danger",
            ]);
            vm.savingCSV = false;
          }
        });
    },
    submitRescheduledGames: function () {
      var vm = this;
      console.log(vm.eventId);
      this.$axios
        .post("/api/save-rescheduled-games", {
          event: vm.eventId,
          announcement: vm.rescheduleAnnouncementSwitch,
          announcement_content: vm.newAnnouncement,
          selected_games: vm.selectedGames,
        })
        .then(async (result) => {
          if (result.data.type === "success") {
            vm.selectedGames = [];
            vm.newAnnouncement.title = null;
            vm.newAnnouncement.description = null;
            vm.rescheduleGameOpen = false;
            vm.postponeGameOpen = false;

            vm.$emit("set-notification", [
              "You have successfully reschedule games. This will be updated on next refresh!",
              "success",
            ]);
            await this.getGames();
          } else {
            vm.$emit("set-notification", [
              "Something went wrong. If this persists, please contact us.",
              "danger",
            ]);
          }
        });
    },
    handleCsvUpload: function () {
      var vm = this;

      var file = this.$refs.file.files[0];
      if (file.type === "text/csv") {
        var results = papa.parse(vm.$refs.file.files[0], {
          header: false,
          complete: function (results) {
            try {
              const dashValues = results.data.filter((item) => {
                if (item[0].includes("-")) {
                  //division
                  return true;
                }
                if (item[7].includes("-")) {
                  //away team
                  return true;
                }
                if (item[8].includes("-")) {
                  //home team
                  return true;
                }

                return false;
              });
              if (dashValues.length > 0) {
                vm.setNotification([
                  "Dashes are not allowed in Divisions and Team names",
                  "danger",
                ]);
                console.log("dashValues", dashValues);
              } else {
                vm.fileData = results.data;
                vm.file = vm.$refs.file.files[0];
              }
            } catch (err) {
              console.log(err);

              vm.setNotification(["Invalid CSV", "danger"]);
            }
          },
        });
      }
    },
    closeReportTheScore: function () {
      this.reportScoreOpen = false;
      this.reportScoreInputs.game = null;
      this.reportScoreInputs.away_team = null;
      this.reportScoreInputs.away_team_freetext = null;
      this.reportScoreInputs.away_score = null;
      this.reportScoreInputs.home_team = null;
      this.reportScoreInputs.home_team_freetext = null;
      this.reportScoreInputs.home_score = null;
      // this.reportScoreInputs.shortcode = null;
      let query = Object.assign({}, this.$route.query);
      delete query.game;
      this.$router.replace({ query });
    },
    deleteGames: function () {
      var vm = this;
      var r = confirm("Are you sure you want to do this?");

      if (r === true) {
        this.$axios
          .post("/api/delete-games", {
            selectedGames: vm.selectedGames,
          })
          .then(async (result) => {
            if (result.data.success === true) {
              vm.$emit("set-notification", [
                "You have successfully delete games. This will be updated on next refresh!",
                "success",
              ]);
            }
          });
      }
    },
    submitPostponedGames: function () {
      var vm = this;
      this.$axios
        .post("/api/save-postponed-games", {
          event: vm.eventId,
          convenor_id: vm.user.id,
          announcement: vm.postponeAnnouncementSwitch,
          announcement_content: vm.newAnnouncement,
          selected_games: vm.selectedGames,
        })
        .then(async (result) => {
          if (result.data.type === "success") {
            // vm.event = result.data.event
            // vm.uploadCSV = false
            vm.selectedGames = [];
            vm.newAnnouncement.title = null;
            vm.newAnnouncement.description = null;
            vm.rescheduleGameOpen = false;
            vm.postponeGameOpen = false;

            vm.$emit("set-notification", [
              "You have successfully postponed games. This will be updated on next refresh!",
              "success",
            ]);
            await this.getGames();
          } else {
            vm.$emit("set-notification", [
              "Something went wrong. If this persists, please contact us.",
              "danger",
            ]);
          }
        });
    },
  },
};
</script>
<style scoped>
.game-modal-rows {
  max-height: 300px;
  overflow: auto;
}
.game-modal-rows table {
  position: relative;
}
.game-modal-rows th {
  position: sticky;
  top: 0;
  background-color: white;
  padding-bottom: 0.5rem;
}
.marquee {
  -webkit-marquee: auto medium infinite scroll normal;
  overflow-x: -webkit-marquee;
}
.current-date {
  position: fixed;
  bottom: 42px;
  left: 0px;
  width: 100%;
  padding: 8px 15px;
  text-align: center;
  background: #262843;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
}
</style>

<style lang="scss">
@import "./assets/scss/dashboard.scss";
</style>

<template>
  <span>
    <div class="frontpage-wrapper">
      <div class="standard-logo-bar">
        <router-link
          :to="{ name: 'Home' }"
          style="
            display: block;
            max-width: 200px;
            margin: 0 auto;
            padding-top: 7px;
          "
        >
          <img
            src="https://cdn.blacktiecollab.com/gamebeast.io/gamebeastlogo.png"
            style="width: 100%"
            alt=""
          />
        </router-link>
      </div>
      <div class="questionnaire-wrapper">
        <div class="questionnaire">
          <div class="question-box">
            <h2>
              <span class="question" v-if="question.question_number"
                >{{ question.question_number }}.</span
              >
              {{ question.question }}
            </h2>
            <label class="text-box" v-if="question.question_type === 'text'">
              <input
                type="text"
                v-bind:style="
                  error_message
                    ? 'border: 1px solid red; outline: none'
                    : '1px solid #aaa;'
                "
                ref="afterClick"
                class="text-box-input"
                placeholder="Provide your answer here."
                v-model="question.value"
                v-on:keydown.enter.prevent="onKeyDown"
              />
            </label>
            <label
              class="text-box"
              v-if="question.question_type === 'password'"
            >
              <input
                type="password"
                v-bind:style="
                  error_message
                    ? 'border: 1px solid red; outline: none'
                    : '1px solid #aaa;'
                "
                ref="afterClick"
                class="text-box-input"
                placeholder="Create a password"
                v-model="question.value"
                v-on:keydown.enter.prevent="onKeyDown"
              />
            </label>
            <div
              class="confirmation-box"
              v-if="question.moves_to === 'finished'"
            >
              <div v-for="(question, key) in questions" :key="key">
                <p>
                  <b>{{ question.question }}</b>
                </p>
                <p v-if="question.question_type !== 'password'">
                  {{ question.value }}
                </p>
                <p v-if="question.question_type === 'password'">*********</p>
              </div>
            </div>

            <div class="errors" style="margin-left: 10px; margin-top: 10px">
              <small class="error" v-if="error_message" style="color: red">
                {{ error_message }}
              </small>
            </div>

            <div class="press-enter" v-if="question.moves_to !== 'finished'">
              press <b>enter</b> or <b>return</b> to continue
            </div>
            <button
              class="submit"
              v-if="question.moves_to === 'finished'"
              v-on:click="submitButton()"
            >
              Click here to submit your registration
            </button>
          </div>
        </div>
      </div>
    </div>
  </span>
  <!-- <router-link to="/">
       Home
     </router-link> -->
</template>

<script>
export default {
  data() {
    return {
      error_message: null,
      currentQuestion: 0,
      questions: [
        {
          header: "Registering is easy!",
          question_number: "1",
          question: "What is your email address?",
          name: "email",
          question_type: "text",
          placeholder: null,
          value: null,
          email: null,
          required: true,
          moves_to: 1,
        },
        {
          header: "Registering is easy!",
          question_number: "2",
          question: "What is your first and last name?",
          name: "full_name",
          question_type: "text",
          placeholder: null,
          value: null,
          required: true,
          moves_to: 2,
        },
        {
          header: "Registering is easy!",
          question_number: "3",
          name: "phone",
          question: "What is your phone number?",
          question_type: "text",
          placeholder: null,
          value: null,
          required: true,
          moves_to: 3,
        },
        {
          header: "Registering is easy!",
          question_number: "4",
          name: "password",
          question: "Create a password for your account",
          question_type: "password",
          placeholder: null,
          value: null,
          required: true,
          moves_to: 4,
        },
        {
          header: "Registering is easy!",
          question_number: null,
          question:
            "You have completed the form. Please confirm the details below and submit it below to continue!",
          question_type: null,
          placeholder: null,
          value: null,
          required: true,
          moves_to: "finished",
        },
      ],
    };
  },
  mounted: function () {
    var vm = this;

    window.addEventListener(
      "keypress",
      function (e) {
        if (e.keyCode === 13) {
          var current_question = vm.questions[vm.currentQuestion];

          if (current_question.moves_to !== "finished") {
            vm.currentQuestion = current_question.moves_to;
          }
          vm.$nextTick(() => {
            if (vm.$refs.afterClick) {
              vm.$refs.afterClick.focus();
            }
          });
        }
      }.bind(this)
    );

    vm.$refs.afterClick.focus();
  },
  computed: {
    question() {
      return this.questions[this.currentQuestion];
    },
  },
  methods: {
    onKeyDown: function (e) {
      var vm = this;
      var current_question = vm.questions[vm.currentQuestion];

      if (current_question.moves_to !== "finished") {
        if (current_question.value) {
          var regExp = new RegExp(/^[\d ]*$/);
          var regex2 =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (
            current_question.question_number == 1 &&
            !regex2.test(current_question.value)
          ) {
            this.error_message = "Email is not valid.";
          } else if (
            current_question.question_number == 3 &&
            !regExp.test(current_question.value)
          ) {
            this.error_message =
              "Phone number needs to be comprised of digits and spaces - no dashes.";
          } else {
            vm.currentQuestion = current_question.moves_to;
            this.error_message = "";
          }
        } else {
          switch (current_question.question_number) {
            case "1":
              this.error_message = "Email is required.";
              break;
            case "2":
              this.error_message = "First and last name is required.";
              break;
            case "3":
              this.error_message = "Phone number is required.";
              break;
            case "4":
              this.error_message = "Password is required.";
              break;
            default:
              this.error_message = "This is required.";
              break;
          }
        }
      }
      vm.$nextTick(() => {
        if (vm.$refs.afterClick) {
          vm.$refs.afterClick.focus();
        }
      });
    },

    submitButton: function () {
      var data = {
        questions: this.questions,
      };

      var vm = this;
      this.disableOnClick = true;

      this.$axios
        .post("/auth/register", {
          questions: vm.questions,
        })
        .then((result) => {
          this.$router.push("/create-event");
        })
        .catch((err) => {
          vm.error_message = "Failed";
        });
    },
  },
};
</script>

<template>
  <div class="dashboard-current-window">
    <div class="row">
      <div class="col-md-3" v-if="!computedAdmin"></div>

      <div class="col-md-6" v-if="computedAdmin">
        <div class="create-new-announcement step">
          <div class="new-announcement">Create a New Announcement</div>
          <label for="">
            <span>Title</span>
            <input type="text" v-model="newAnnouncement.title" />
          </label>
          <label for="">
            <span>Description</span>
            <textarea v-model="newAnnouncement.description"></textarea>
          </label>
          <div class="button button-blue" v-on:click="submitAnnouncement()">
            Submit Announcement
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div
          class="previous-announcement"
          v-for="(announcement, key) in event.announcements"
          :key="key"
        >
          <div class="previous-announcement-header">
            <div class="row">
              <div class="col-md-8">
                {{ announcement.title }}
              </div>
              <div class="col-md-4">
                <span
                  >Sent on
                  {{
                    moment(announcement.created_at).format("MM/DD/YYYY h:mm A")
                  }}</span
                >
              </div>
            </div>
          </div>
          <div class="previous-announcement-content">
            {{ announcement.description }}
          </div>
        </div>
      </div>
      <div class="col-md-3" v-if="!computedAdmin"></div>
    </div>
    <div id="adarea">
    <scriptx async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7578943240214333"
     crossorigin="anonymous"></scriptx>
    <!-- Umpire Cheat Sheet Bottom -->
    <ins class="adsbygoogle"
        style="display:block"
        data-ad-client="ca-pub-7578943240214333"
        data-ad-slot="9145934530"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
    <scriptx>
        (adsbygoogle = window.adsbygoogle || []).push({});
    </scriptx>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import tz from "moment-timezone";
import { mapState, mapActions } from "vuex";
export default {
  name: "AnnouncementsPage",
  // To use props, they must be declared
  props: {
    event: Object,
    computedAdmin: Boolean,
  },
  data: function () {
    return {
      newAnnouncement: {
        title: null,
        description: null,
      },
    };
  },
  computed: {
    ...mapState(["user", "clientTimezone"]),
  },
  methods: {
    moment: function (date, format = null) {
      return moment.tz(date, format, this.clientTimezone);
    },
    submitAnnouncement: function () {
      var vm = this;

      this.$axios
        .post(`/api/events/${vm.event.id}/announcements`, {
          announcement: vm.newAnnouncement,
        })
        .then((result) => {
          if (result.data.type === "success") {
            vm.newAnnouncement.title = null;
            vm.newAnnouncement.description = null;

            vm.event.announcements.push(result.data.announcement);

            vm.$emit("set-notification", [
              "New announcement posted and queued for email and mobile popup.",
              "success",
            ]);
          } else {
            vm.$emit("set-notification", [
              "Something went wrong. If this persists, please contact us.",
              "danger",
            ]);
          }
        });
    },
  },
};
</script>

<template>
  <div>
    <span class="home">
      <div class="standard-logo-bar">
        <router-link
          :to="{ name: 'Home' }"
          style="
            display: block;
            max-width: 200px;
            margin: 0 auto;
            padding-top: 7px;
          "
        >
          <img
            src="https://cdn.blacktiecollab.com/gamebeast.io/gamebeastlogo.png"
            style="width: 100%"
            alt=""
          />
        </router-link>
      </div>
    </span>
    <div class="dashboard-wrapper">
      <div class="adswrapper">
        <Adsense
          data-ad-client="ca-pub-7578943240214333"
          data-ad-slot="2396693351"
        >
        </Adsense>
      </div>
    </div>
    <div class="dashboard-wrapper" v-if="complete === true">
      <h1>Your info is successfully submitted!</h1>
      <p>
        Your coach will see the information when they next load into the roster.
        Thanks for registering!
      </p>
    </div>
    <div class="dashboard-wrapper" v-if="complete === false">
      <h1>We need your info!</h1>
      <p>Fill out your info below .</p>
      <p>
        For "Current Rank" - please enter the rank you are listed at - and
        answer honestly - these will be cross referenced!
      </p>
      <br /><br />
      <div class="table-overflow">
        <table class="standings-table">
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Current Rank</th>
            <th></th>
          </tr>
          <tr v-for="(player, key) of players" :key="key">
            <td>
              <input type="text" v-model="player.first_name" />
              <label
                v-for="error of v$.players[key].first_name.$errors"
                :key="error.$uid"
              >
                <strong style="color: red">{{ error.$message }}</strong>
              </label>
            </td>
            <td>
              <input type="text" v-model="player.last_name" />
              <label
                v-for="error of v$.players[key].last_name.$errors"
                :key="error.$uid"
              >
                <strong style="color: red">{{ error.$message }}</strong>
              </label>
            </td>
            <td>
              <input type="text" v-model="player.email" />
              <label
                v-for="error of v$.players[key].email.$errors"
                :key="error.$uid"
              >
                <strong style="color: red">{{ error.$message }}</strong>
              </label>
            </td>
            <td>
              <input type="text" v-model="player.phone" />
              <label
                v-for="error of v$.players[key].phone.$errors"
                :key="error.$uid"
              >
                <strong style="color: red">{{ error.$message }}</strong>
              </label>
            </td>
            <td>
              <select name="" id="" v-model="player.current_rank">
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
                <option value="D">D</option>
                <option value="E">E</option>
                <option value="Unranked">Unranked</option>
              </select>
            </td>
          </tr>
        </table>

        <a class="button" v-on:click="submitPlayer()">Submit</a>
      </div>

      <!-- fill out your roster -->
    </div>
  </div>
  <div class="dashboard-wrapper">
    <div class="adswrapper">
      <Adsense
        data-ad-client="ca-pub-7578943240214333"
        data-ad-slot="9145934530"
      >
      </Adsense>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
const mustBeValid = (value) => isValidPhoneNumber(value, "CA");
export default {
  data() {
    return {
      players: [
        {
          first_name: null,
          last_name: null,
          email: null,
          phone: null,
          current_rank: "E",
        },
      ],
      complete: false,
    };
  },
  mounted: function () {
    var code = this.$route.params.code;
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      players: [
        {
          email: { required, email },
          first_name: { required },
          last_name: { required },
          phone: {
            required,
            isValid: helpers.withMessage("Invalid Phone Number", mustBeValid),
          },
        },
      ],
    };
  },

  methods: {
    submitPlayer: function () {
      this.v$.$touch();
      if (this.v$.$error) {
        console.log("errors", this.v$.$errors);
        window.scrollTo(0, 0);
        return;
      }
      this.players[0].phone = parsePhoneNumber(
        this.players[0].phone,
        "CA"
      ).nationalNumber;
      var code = this.$route.params.code;
      this.$axios
        .post("/api/save-player", {
          code: code,
          player: this.players[0],
        })
        .then((results) => {
          if (results.data.success === true) {
            this.complete = true;
          }
        });
    },
  },
};
</script>

<style scoped>
.is-verified {
  padding: 100px 30px;
  text-align: center;
}
.dashboard-wrapper {
  margin: 0 auto;
  margin-top: 100px;
  max-width: 1000px;
}

h1 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 15px;
}

h2 {
  font-size: 22px;
  margin-top: 10px;
  font-weight: 700;
}

.button {
  background: #000;
  color: #fff;
  font-weight: 700;
  padding: 15px 20px;
  margin: 10px 5px;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
}

.button-small {
  padding: 5px 10px;
}

.button:hover {
  background: #333;
  transition: 0.5s;
}
.standings-table {
  width: 100%;
}
.standings-table th {
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px;
  background: #ccc;
}

.standings-table td {
  padding: 10px;
  text-align: center;
}

.standings-table input {
  padding: 5px 10px;
}

.standings-table select {
  text-align: center;
  padding: 5px 10px;
}

.table-overflow {
  width: 100%;
  overflow: scroll;
}
</style>

import axios from "axios";
import store from "../store";
import extractCookie from "./extractCookie";

export default function axiosSetUp() {
  if (process.env.NODE_ENV === "development") {
    axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
  } else {
    axios.defaults.baseURL = window.location.origin + "/api";
  }
  axios.defaults.withCredentials = true;

  const blacklist = ["/auth/logout", "/auth/refresh", "/auth/attempt"];

  axios.interceptors.request.use(
    function (config) {
      if (!blacklist.includes(config.url)) {
        const token = extractCookie("access_token");
        if (!token) {
          store.dispatch("refreshToken");
        }
      }
      return config;
    },
    function (error) {
      console.log("Interceptor Error");
      store.dispatch("logout");
      return Promise.reject(error);
    }
  );
}

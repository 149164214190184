import _ from "lodash";
import moment from "moment";
import tz from "moment-timezone";
import Header from "../../components/dashboard/Header.vue";
import Navigation from "../../components/dashboard/Navigation.vue";
import TeamPage from "../../components/dashboard/TeamPage.vue";
import StandingsPage from "../../components/dashboard/StandingsPage.vue";
import AnnouncementsPage from "../../components/dashboard/AnnouncementsPage.vue";
import RulesPage from "../../components/dashboard/RulesPage.vue";
import InfoPage from "../../components/dashboard/InfoPage.vue";
import SchedulePage from "../../components/dashboard/SchedulePage.vue";
import papa from "papaparse";
import { mapState, mapActions } from "vuex";
export default {
  name: "Dashboard",
  components: {
    Header,
    Navigation,
    TeamPage,
    StandingsPage,
    AnnouncementsPage,
    RulesPage,
    InfoPage,
    SchedulePage,
  },
  data: function () {
    return {
      event: null,
      title: "About Page",
      savingCSV: false,
      menuNav: false,

      navigationValue: null,
      navigationObject: null,
      stepOne: false,
      stepTwo: false,
      stepThree: false,
      postponeAnnouncementSwitch: false,
      rescheduleAnnouncementSwitch: false,
      loginForm: true,
      loginFormOpen: false,
      leagueDetailsEdit: false,
      changeLeaguesOpen: false,
      rescheduleGamesSelector: false,

      notificationText: null,
      notificationType: null,
      preventScroll: false,
      divisions: [
        {
          division_id: 1,
          name: "Men's A",
          teams: [
            {
              name: null,
              password: null,
              coach_name: null,
              coach_email: null,
              secondary_name: null,
              secondary_email: null,
            },
          ],
        },
      ],
    };
  },
  mounted: function () {
    var vm = this;

    if (typeof window !== "undefined") {
      window.addEventListener("keypress", function (e) {}.bind(this));
    }

    if (vm.event) {
      document.title = vm.event.name + " - Gamebeast.io";
    } else {
      document.title = "Gamebeast.io";
    }

    let slug = this.$route.params.slug;
    vm.navigationValue = this.$route.params.nav;

    this.$axios.get("/api/find-league/" + slug).then((results) => {
      console.log("fired", results);

      vm.event = results.data.event;
      document.title = vm.event.name + " - Gamebeast.io";
    });

    setInterval(function () {
      if (vm.notificationText) {
        vm.notificationText = null;
      }
    }, 5000);
    console.log(this.$route.params.slug);
  },
  computed: {
    ...mapState(["user", "clientTimezone"]),
    computedAdmin: function () {
      // check if there's a user - if no user, false
      // TO DO - FIGURE OUT HOW THIS VALIDATION WORKS
      if (this.user) {
        console.log("computedAdmin", this.event);
        // check if user id === event, if it doesn't, false
        if (this.user.id === this.event.convenor_id) {
          return true;
        }
        return false;
      }
      return false;
    },

    verifyStepTwo: function () {
      if (this.event.teams.length > 0) {
        return true;
      }

      return false;
    },
    verifyStepThree: function () {
      if (this.event.games.length > 0) {
        return true;
      }

      return false;
    },
    verifyQuickStartCompletion: function () {
      if (this.verifyStepTwo && this.verifyStepThree) {
        return true;
      }

      return false;
    },
  },
  watch: {
    $route(value) {
      this.navigationValue = value.params.nav;
    },
  },
  methods: {
    moment: function (date, format = null) {
      return moment.tz(date, format, this.clientTimezone);
    },
    updateLoggedInUser: async function (data) {
      console.log(data);
      this.user = data.user;
      await sessionStorage.setItem("user", JSON.stringify(data.user));
    },
    selectNavItem: function (item) {
      var vm = this;
      var old_value = this.navigationValue;
      this.navigationObject = item;

      console.log("router", this.$route.params);
      if (old_value !== item.value) {
        this.$router.push({
          name: "Dashboard",
          params: { slug: this.$route.params.slug, nav: item.value },
        });
        this.navigationValue = item.value;
      }
    },

    setNotification: function (notification) {
      this.notificationText = notification[0];
      this.notificationType = notification[1];
    },
    handleCsvUpload: function () {
      var vm = this;
      this.file = this.$refs.file.files[0];
      var file = this.file;
      if (this.file.type === "text/csv") {
        var results = papa.parse(vm.$refs.file.files[0], {
          header: false,
          complete: function (results) {
            vm.fileData = results.data;
          },
        });
      } else {
        console.log("nothing");
      }
    },
  },
};

// addDivision: function() {
//     var vm = this

//     vm.divisions.push({
//         division_id: vm.divisions.length + 1,
//         name: null,
//         teams: [{
//             name: null,
//             password: null,
//             coach_name: null,
//             coach_email: null,
//             secondary_name: null,
//             secondary_email: null
//         }]
//     })
// },
// addTeam: function(division) {
//     division.teams.push({
//         name: null,
//         password: null,
//         coach_name: null,
//         coach_email: null,
//         secondary_name: null,
//         secondary_email: null
//     })
// },
// removeTeam: function(division, division_key, team) {
//     var vm = this
//     var confirmation = confirm('Are you sure you want to do this?')

//     if(confirmation === true) {
//         var teams = _.filter(division.teams, function(the_team, the_key) {
//             return team !== the_key
//         })

//         division.teams = teams
//     }

// },
// saveDivisionsAndTeams: function() {
//     var vm = this
//     axios.post('/api/save-divisions-and-teams', {
//         user: user.id,
//         event: event.id,
//         divisions:  vm.divisions
//     }).then(result => {
//         if(result.type === 'success') {
//             vm.stepTwo = true
//             vm.setNotification('Divisions and teams saved successfully.', 'success')
//         } else {
//             // post the error here
//             vm.setNotification('Something went wrong. If this persists, please contact us.', 'danger')
//         }
//     })
// },

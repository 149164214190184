import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ScriptX from "vue-scriptx";
import Adsense from "vue-google-adsense/dist/Adsense.min.js";
import axios from "axios";
import axiosSetup from "./helpers/interceptors";

axiosSetup();

var app = createApp(App).use(store).use(router).use(ScriptX).use(Adsense);

app.config.globalProperties.$axios = axios;

router.isReady().then(() => {
  app.mount("#app");
});

<template>
  <div class="dashboard-current-window" v-if="loading">
    Loading please wait!
  </div>
  <div class="dashboard-current-window" v-if="!loading">
    <div id="adarea">
      <scriptx
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7578943240214333"
        crossorigin="anonymous"
      ></scriptx>
      <!-- Umpire Cheat Sheet Bottom -->
      <ins
        class="adsbygoogle"
        style="display: block"
        data-ad-client="ca-pub-7578943240214333"
        data-ad-slot="2396693351"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
      <scriptx> (adsbygoogle = window.adsbygoogle || []).push({}); </scriptx>
    </div>
    <div :class="['tool-bar', { mobile: isMobile }]" v-if="computedAdmin">
      <div class="inner-scroll">
        <a class="button" @click="resetStandings()"> Reset Standings </a>
      </div>
    </div>
    <div
      class="division-row"
      v-for="(division, key) in computedStandings"
      :key="key"
    >
      <div class="division-header">{{ division.division_name }} Teams</div>
      <div class="overflow-table-scroll">
        <table class="standings-table">
          <tr class="table-header">
            <td></td>
            <td style="width: 275px">Team Name</td>
            <td>GP</td>
            <td>Wins</td>
            <td>Losses</td>
            <td>Ties</td>
            <td>Pts</td>
            <td>RF</td>
            <td>RA</td>
            <td>+/-</td>
            <td v-if="computedAdmin">Actions</td>
          </tr>
          <tr v-for="(team, team_key) in division.teams" :key="team_key">
            <td>
              <b>{{ team_key + 1 }}</b>
            </td>
            <td style="width: 275px; text-align: left; font-weight: 700">
              {{ team.name }}
            </td>
            <td>
              {{
                parseInt(team.wins) +
                parseInt(team.losses) +
                parseInt(team.ties)
              }}
            </td>
            <td>
              <span v-if="team !== editTeam">{{ team.wins }}</span>
              <input
                v-if="team === editTeam"
                type="text"
                class="league-edit display center"
                v-model="edit.wins"
              />
            </td>
            <td>
              <span v-if="team !== editTeam">{{ team.losses }}</span>
              <input
                v-if="team === editTeam"
                type="text"
                class="league-edit display center"
                v-model="edit.losses"
              />
            </td>
            <td>
              <span v-if="team !== editTeam">{{ team.ties }}</span>
              <input
                v-if="team === editTeam"
                type="text"
                class="league-edit display center"
                v-model="edit.ties"
              />
            </td>
            <td>{{ team.points }}</td>
            <td>
              <span v-if="team !== editTeam">{{ team.runs_for }}</span>
              <input
                v-if="team === editTeam"
                type="text"
                class="league-edit display center"
                v-model="edit.runs_for"
              />
            </td>
            <td>
              <span v-if="team !== editTeam">{{ team.runs_against }}</span>
              <input
                v-if="team === editTeam"
                type="text"
                class="league-edit display center"
                v-model="edit.runs_against"
              />
            </td>
            <td>{{ team.plus_minus }}</td>
            <td v-if="computedAdmin">
              <div
                class="button button-purple"
                v-if="team !== editTeam"
                v-on:click="editStanding(team)"
              >
                Edit Standings
              </div>
              <div
                class="button button-blue"
                v-if="team === editTeam"
                v-on:click="saveStandings()"
              >
                Save Standings
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div id="adarea">
      <scriptx
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7578943240214333"
        crossorigin="anonymous"
      ></scriptx>
      <!-- Umpire Cheat Sheet Bottom -->
      <ins
        class="adsbygoogle"
        style="display: block"
        data-ad-client="ca-pub-7578943240214333"
        data-ad-slot="9145934530"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
      <scriptx> (adsbygoogle = window.adsbygoogle || []).push({}); </scriptx>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions } from "vuex";
var mobile = require("is-mobile");
export default {
  name: "StandingsPage",
  // To use props, they must be declared
  props: {
    computedAdmin: Boolean,
    teams: Array,
    eventId: Number,
    pointsForWin: Number,
    pointsForLoss: Number,
    pointsForTie: Number,
  },
  emits: ["set-notification"],
  data: function () {
    return {
      edit: null,
      editTeam: null,
      file: null,
      fileData: null,
      loading: false,
    };
  },
  computed: {
    isMobile() {
      return mobile();
    },
    computedDivisions: function () {
      // take all the teams, pull divisions via a grouping
      var vm = this;
      var teams = vm.teams;

      var group = _.groupBy(teams, "division_name");
      var divisions = Object.keys(group);

      var array = [];

      var i = 0;

      for (i; i < divisions.length; i++) {
        var division = divisions[i];

        array.push({
          division_name: division,
          teams: [],
        });
      }

      var o = 0;

      for (o; o < array.length; o++) {
        division = array[o];

        var filtered_teams = _.filter(teams, function (team) {
          return team.division_name === division.division_name;
        });

        array[o].teams = filtered_teams;
      }

      return array;
    },
    computedStandings: function () {
      var vm = this;

      var divisions = this.computedDivisions;

      var i = 0;
      // calculate points
      for (i; i < divisions.length; i++) {
        var division = divisions[i];

        var teams = division.teams;

        var o = 0;

        for (o; o < teams.length; o++) {
          teams[o].points =
            parseInt(teams[o].wins) * parseInt(vm.pointsForWin) +
            parseInt(teams[o].losses) * parseInt(vm.pointsForLoss) +
            parseInt(teams[o].ties) * parseInt(vm.pointsForTie);
          teams[o].plus_minus =
            parseInt(teams[o].runs_for) - parseInt(teams[o].runs_against);
        }

        divisions[i].teams = _.orderBy(
          division.teams,
          ["points", "wins", "losses", "plus_minus"],
          ["desc", "desc", "asc", "desc"]
        );
      }

      return divisions;
    },
  },
  methods: {
    ...mapActions(["setNotification"]),
    editStanding(team) {
      this.edit = { ...team };
      this.editTeam = team;
    },
    saveStandings: function () {
      var vm = this;
      if (!_.isEqual(this.edit, this.editTeam)) {
        this.$axios
          .post("/api/save-standings", {
            event: vm.eventId,
            team: vm.edit,
          })
          .then((result) => {
            console.log(result);
            if (result.data.type === "success") {
              Object.assign(vm.editTeam, vm.edit);
              vm.edit = null;
              vm.editTeam = null;

              vm.$emit("set-notification", [
                "Standings successfully updated.",
                "success",
              ]);
            } else {
              vm.$emit("set-notification", [
                "Something went wrong. If this persists, please contact us.",
                "danger",
              ]);
            }
          });
      } else {
        vm.edit = null;
        vm.editTeam = null;
      }
    },
    async resetStandings() {
      const ch = confirm(
        "Are you sure you want to reset standings ?\nThe Page will refresh after reset"
      );
      if (ch) {
        this.setNotification(["Resetting in progress. Please Wait", "danger"]);
        this.loading = true;
        const result = await this.$axios
          .post("/api/reset-standings", {
            event_id: this.eventId,
          })
          .catch((err) => {
            this.setNotification(["Reset Failed. Please try again", "danger"]);
          });
        if (result.data.success) {
          this.setNotification([
            "Reset Successfull. Please Refresh",
            "success",
          ]);
          window.location.reload();
        }
        this.loading = false;
      }
    },
  },
};
</script>

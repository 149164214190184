<template>
  <div class="dashboard-current-window">
    <div id="adarea">
      <scriptx
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7578943240214333"
        crossorigin="anonymous"
      ></scriptx>
      <!-- Umpire Cheat Sheet Bottom -->
      <ins
        class="adsbygoogle"
        style="display: block"
        data-ad-client="ca-pub-7578943240214333"
        data-ad-slot="2396693351"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
      <scriptx> (adsbygoogle = window.adsbygoogle || []).push({}); </scriptx>
    </div>
    <div class="rule-book">
      <div class="step padding">
        <div class="step-header">League Rules</div>
        <div class="step-content" v-if="computedAdmin">
          <textarea
            class="rulebook"
            name="rulebook"
            id=""
            cols="30"
            rows="10"
            v-model="rules"
          ></textarea>
          <a v-on:click="saveRules()" class="button button-blue"
            >Save Changes</a
          >
        </div>
        <div
          class="step-content"
          style="white-space: pre-wrap"
          v-if="!computedAdmin"
          v-html="rules"
        ></div>
      </div>
    </div>
    <div id="adarea">
      <scriptx
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7578943240214333"
        crossorigin="anonymous"
      ></scriptx>
      <!-- Umpire Cheat Sheet Bottom -->
      <ins
        class="adsbygoogle"
        style="display: block"
        data-ad-client="ca-pub-7578943240214333"
        data-ad-slot="9145934530"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
      <scriptx> (adsbygoogle = window.adsbygoogle || []).push({}); </scriptx>
    </div>
  </div>
</template>

<script>
export default {
  name: "RulesPage",
  // To use props, they must be declared
  data() {
    return {
      rules: null,
    };
  },
  props: {
    event: Object,
    computedAdmin: Boolean,
  },
  mounted() {
    this.rules = this.event.rules;
  },
  methods: {
    saveRules: function () {
      // axios post league details
      var vm = this;
      this.$axios
        .patch(`/api/events/${vm.event.id}/rules`, {
          rules: vm.rules,
          event_id: vm.event.id,
        })
        .then((result) => {
          if (result.data.type === "success") {
            vm.$emit("set-notification", [
              "Rules have been updated.",
              "success",
            ]);
            this.$router.go(0);
          } else {
            vm.$emit("set-notification", [
              "Something went wrong. If this persists, please contact us.",
              "danger",
            ]);
          }
        });
    },
  },
};
</script>

<script src="./assets/js/dashboard.js"></script>

<style lang="scss">
@import "./assets/scss/dashboard.scss";
</style>

<template>
  <span>
    <div class="loading-wrapper" v-if="!event">Loading - please wait!</div>
    <div class="dashboard-wrapper" v-if="event">
      <div
        :class="['inline-notification', notificationType]"
        v-if="notificationText"
      >
        {{ notificationText }}
        <span class="close-button" v-on:click="notificationText = null"
          ><i class="fas fa-times-circle"></i
        ></span>
      </div>

      <Header
        @set-notification="setNotification"
        @send-user="updateLoggedInUser"
        :name="event.name"
        :slug="event.slug"
        :type="event.type"
        :user="user"
      >
      </Header>

      <Navigation
        @selectnav="selectNavItem"
        :navigationValue="navigationValue"
      ></Navigation>

      <div class="dashboard-main-content">
        <SchedulePage
          v-if="navigationValue === 'Schedule'"
          @set-notification="setNotification"
          :teams="event.teams"
          :event="event"
          :eventId="event.id"
          :computedAdmin="computedAdmin"
        ></SchedulePage>

        <TeamPage
          v-if="navigationValue === 'Teams'"
          @set-notification="setNotification"
          :computedAdmin="computedAdmin"
          :teams="event.teams"
          :eventId="event.id"
        >
        </TeamPage>

        <StandingsPage
          v-if="navigationValue === 'Standings'"
          @set-notification="setNotification"
          :computedAdmin="computedAdmin"
          :teams="event.teams"
          :pointsForWin="event.points_for_win"
          :pointsForLoss="event.points_for_loss"
          :pointsForTie="event.points_for_tie"
          :eventId="event.id"
        >
        </StandingsPage>

        <AnnouncementsPage
          v-if="navigationValue === 'Announcements'"
          @set-notification="setNotification"
          :computedAdmin="computedAdmin"
          :event="event"
        >
        </AnnouncementsPage>

        <RulesPage
          v-if="navigationValue === 'Rules'"
          @set-notification="setNotification"
          :computedAdmin="computedAdmin"
          :event="event"
        >
        </RulesPage>

        <InfoPage
          v-if="navigationValue === 'Info'"
          @set-notification="setNotification"
          :computedAdmin="computedAdmin"
          :event="event"
          :leagueDetailsEdit="leagueDetailsEdit"
          :user="user"
        >
        </InfoPage>
      </div>
    </div>
  </span>
  <!-- <router-link to="/">
       Home
     </router-link> -->
</template>

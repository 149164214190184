<template>
  <div class="dashboard-current-window info">
  
  
    <div class="row">
      <div class="col-md-3" v-if="!computedAdmin"></div>
      <div class="col-md-6">
        <div v-if="computedAdmin">
          <a
            class="button button-green"
            style="margin-left: 10px"
            v-on:click="editLeagueDetailsSwitch = !editLeagueDetailsSwitch"
            >Edit League Details</a
          >

          <a
            class="button button-blue"
            style="margin-left: 10px"
            v-on:click="saveLeagueDetails()"
            v-if="editLeagueDetailsSwitch"
            >Save League Details</a
          >
          <a
            class="button"
            style="margin-left: 10px; background: red"
            v-on:click="eraseSchedule()"
            >Erase Schedule, Teams & Divisions</a
          >
        </div>
        <div class="step padding" v-if="event">
          <div class="step-header">League Details</div>
          <div class="step-content">
            <table class="standings-table">
              <tr>
                <td class="question">League Name</td>
                <td>
                  <div v-if="!editLeagueDetailsSwitch">
                    {{ event.name }}
                  </div>
                  <input
                    type="text"
                    v-model="localEvent.name"
                    class="league-edit"
                    v-if="editLeagueDetailsSwitch"
                  />
                </td>
              </tr>
              <tr>
                <td class="question">Event Shortcode</td>
                <td>
                  <div v-if="!editLeagueDetailsSwitch">
                    {{ event.shortcode }}
                  </div>
                  <input
                    type="text"
                    v-model="localEvent.shortcode"
                    class="league-edit"
                    v-if="editLeagueDetailsSwitch"
                  />
                </td>
              </tr>
              <tr>
                <td class="question">Points for Win</td>
                <td>
                  <div v-if="!editLeagueDetailsSwitch">
                    {{ event.points_for_win }}
                  </div>
                  <input
                    type="text"
                    v-model="localEvent.points_for_win"
                    class="league-edit"
                    v-if="editLeagueDetailsSwitch"
                  />
                </td>
              </tr>
              <tr>
                <td class="question">Points for Loss</td>
                <td>
                  <div v-if="!editLeagueDetailsSwitch">
                    {{ event.points_for_loss }}
                  </div>
                  <input
                    type="text"
                    v-model="localEvent.points_for_loss"
                    class="league-edit"
                    v-if="editLeagueDetailsSwitch"
                  />
                </td>
              </tr>
              <tr>
                <td class="question">Points for Tie</td>
                <td>
                  <div v-if="!editLeagueDetailsSwitch">
                    {{ event.points_for_tie }}
                  </div>
                  <input
                    type="text"
                    v-model="localEvent.points_for_tie"
                    class="league-edit"
                    v-if="editLeagueDetailsSwitch"
                  />
                </td>
              </tr>
              
              <tr>
                <td class="question">Show Home/Away</td>
                <td>
                  <div v-if="!editLeagueDetailsSwitch">
                    {{ event.show_home_away === 1 ? 'Yes' : 'No' }}
                  </div>
                  <select v-model="localEvent.show_home_away" v-if="editLeagueDetailsSwitch" id="">
                    <option :value="1">Yes</option>
                    <option :value="0">No</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td class="question">Deposit Owed</td>
                <td>
                  <div v-if="!editLeagueDetailsSwitch">
                    {{ event.deposit_amount }}
                  </div>
                  <input v-model="localEvent.deposit_amount" v-if="editLeagueDetailsSwitch" id=""/>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-6" v-if="computedAdmin">
        <div v-if="computedAdmin">
          <a
            class="button button-green"
            style="margin-left: 10px"
            v-on:click="accountDetailsEdit = !accountDetailsEdit"
            >Edit Your Details</a
          >

          <a
            class="button button-blue"
            style="margin-left: 10px"
            v-on:click="saveAccountDetails()"
            v-if="accountDetailsEdit"
            >Save Your Details</a
          >
        </div>
        <div class="step padding">
          <div class="step-header">Your Account</div>
          <div class="step-content">
            <table class="standings-table">
              <tr>
                <td class="question">Full Name</td>
                <td>
                  <div v-if="!accountDetailsEdit">
                    {{ user.full_name }}
                  </div>
                  <input
                    type="text"
                    v-model="localUser.full_name"
                    class="league-edit"
                    v-if="accountDetailsEdit"
                  />
                </td>
              </tr>
              <tr>
                <td class="question">Email</td>
                <td>
                  <div v-if="!accountDetailsEdit">{{ user.email }}</div>
                  <input
                    type="text"
                    v-model="localUser.email"
                    class="league-edit"
                    v-if="accountDetailsEdit"
                  />
                </td>
              </tr>
              <tr>
                <td class="question">Phone</td>
                <td>
                  <div v-if="!accountDetailsEdit">{{ user.phone }}</div>
                  <input
                    type="text"
                    v-model="localUser.phone"
                    class="league-edit"
                    v-if="accountDetailsEdit"
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-3" v-if="!computedAdmin"></div>
    </div>
    <div id="adarea">
    <scriptx async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7578943240214333"
     crossorigin="anonymous"></scriptx>
    <!-- Umpire Cheat Sheet Bottom -->
    <ins class="adsbygoogle"
        style="display:block"
        data-ad-client="ca-pub-7578943240214333"
        data-ad-slot="9145934530"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
    <scriptx>
        (adsbygoogle = window.adsbygoogle || []).push({});
    </scriptx>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoPage",
  // To use props, they must be declared
  data: function () {
    return {
      accountDetailsEdit: false,
      editLeagueDetailsSwitch: false,
      localEvent: null,
      localUser: null,
    };
  },
  props: {
    event: Object,
    computedAdmin: Boolean,
    leagueDetailsEdit: Boolean,
    user: Object,
  },
  mounted: function () {
    this.localEvent = this.event;
    this.localUser = this.user;
  },
  methods: {
    saveLeagueDetails: function () {
      // axios post league details
      var vm = this;
      this.$axios
        .post("/api/save-league-details", {
          event: vm.event.id,
          convenor_id: vm.event.convenor_id,
          name: vm.event.name,
          shortcode: vm.event.shortcode,
          points_for_win: vm.event.points_for_win,
          points_for_loss: vm.event.points_for_loss,
          points_for_tie: vm.event.points_for_tie,
          show_home_away: vm.event.show_home_away,
          deposit_amount: vm.event.deposit_amount,
        })
        .then((result) => {
          if (result.data.type === "success") {
            vm.$emit("set-notification", [
              "League details updated.",
              "success",
            ]);
            vm.leagueDetailsEdit = false;
            vm.$emit("set-notification", [
              "League details updated.",
              "success",
            ]);
          } else {
            vm.$emit("set-notification", [
              "Something went wrong. If this persists, please contact us.",
              "danger",
            ]);
          }
        });
    },
    saveAccountDetails: function () {
      // axios post league details
      var vm = this;
      this.$axios
        .post("/auth/save-your-details", {
          user: vm.user,
        })
        .then(async (result) => {
          this.$store.dispatch("refreshToken");
          vm.accountDetailsEdit = false;
          vm.$emit("set-notification", [
            "Your account details have been updated.",
            "success",
          ]);
        })
        .catch((err) => {
          console.log(err);
          vm.$emit("set-notification", [
            "Something went wrong. If this persists, please contact us.",
            "danger",
          ]);
        });
    },
    eraseSchedule() {
      const response = confirm("Are you sure ?");
      var vm = this
      if(response === true) {
        this.$axios
        .post("/api/erase-schedule", {
          event_id: vm.event.id
        })
        .then(async (result) => {
          this.$store.dispatch("refreshToken");
          vm.accountDetailsEdit = false;
          vm.$emit("set-notification", [
            "Schedule has been erased.",
            "success",
          ]);
        })
        .catch((err) => {
          console.log(err);
          vm.$emit("set-notification", [
            "Something went wrong. If this persists, please contact us.",
            "danger",
          ]);
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "./assets/scss/dashboard.scss";
</style>

<template>
  <span>
    <div class="frontpage-wrapper" id="application">
      <div class="standard-logo-bar">
        <router-link
          :to="{ name: 'Home' }"
          style="
            display: block;
            max-width: 200px;
            margin: 0 auto;
            padding-top: 7px;
          "
        >
          <img
            src="https://cdn.blacktiecollab.com/gamebeast.io/gamebeastlogo.png"
            style="width: 100%"
            alt=""
          />
        </router-link>
      </div>
      <div class="questionnaire-wrapper">
        <div class="questionnaire">
          <div class="question-box">
            <h2>
              <span class="question" v-if="question.question_number"
                >{{ question.question_number }}.</span
              >
              {{ question.question }}
            </h2>
            <div v-if="question.question_type === 'text'">
              <label class="text-box">
                <input
                  type="text"
                  ref="afterClick"
                  class="text-box-input"
                  placeholder="Provide your answer here."
                  v-model="question.value"
                />
              </label>
              <div class="press-enter">
                press <b>enter</b> or <b>return</b> to continue
              </div>
            </div>
            <div v-if="question.question_type === 'password'">
              <label class="text-box">
                <input
                  type="password"
                  ref="afterClick"
                  class="text-box-input"
                  placeholder="Create a password"
                  v-model="question.value"
                />
              </label>
              <div class="press-enter">
                press <b>enter</b> or <b>return</b> to continue
              </div>
            </div>
            <div
              class="multiple-choice"
              v-if="question.question_type === 'multiple_choice'"
            >
              <input type="hidden" ref="afterClick" />
              <div class="options">
                <div
                  :class="[
                    'option',
                    { selected: option.value === question.value },
                  ]"
                  v-for="(option, key) in question.options"
                  v-on:click="selectMC(question, option.value)"
                  :key="key"
                >
                  <span>{{ key + 1 }} </span>
                  {{ option.value }}
                </div>
              </div>
              <button
                class="submit"
                v-on:click="
                  currentQuestion++;
                  afterFocus();
                "
              >
                next question
              </button>
              <div class="press-enter">click to select an option</div>
            </div>
            <div
              class="confirmation-box"
              v-if="question.question_type === 'finished'"
            >
              <div v-for="(question, key) in questions" :key="key">
                <p>
                  <b>{{ question.question }}</b>
                </p>
                <p v-if="question.question_type !== 'password'">
                  {{ question.value }}
                </p>
                <p v-if="question.question_type === 'password'">*********</p>
              </div>
            </div>
            <div class="errors" style="margin-left: 10px; margin-top: 10px">
              <small class="error" v-if="error_message" style="color: red">
                {{ error_message }}
              </small>
            </div>
            <button
              class="submit"
              v-on:click="createEvent()"
              v-if="question.moves_to === 'finished'"
            >
              Click here to create your event!
            </button>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
export default {
  data() {
    return {
      currentQuestion: 0,
      header: "It's time to unleash your first league/tournament",
      error_message: null,
      questions: [
        {
          question_number: "1",
          question: "Is this a league or a tournament?",
          question_type: "multiple_choice",
          options: [
            {
              key: "league",
              value: "League",
            },
            {
              key: "tournament",
              value: "Tournament",
            },
          ],
          name: "type",
          placeholder: null,
          value: "League",
          required: true,
          moves_to: 1,
        },
        {
          question_number: "2",
          question: "What's the name of this league or tournament?",
          question_type: "text",
          name: "name",
          placeholder: null,
          value: null,
          required: true,
          moves_to: 2,
        },
        {
          question_number: "3",
          question:
            "Create a shortcode. Something like MLB, for Major League Baseball:",
          question_type: "text",
          name: "shortcode",
          placeholder: null,
          value: null,
          required: true,
          moves_to: 3,
        },
        {
          question_number: "4",
          question: "How many points for a win?",
          question_type: "text",
          name: "points_for_win",
          placeholder: null,
          value: 2,
          required: true,
          moves_to: 4,
        },
        {
          question_number: "5",
          question: "How many points for a loss?",
          name: "points_for_loss",
          question_type: "text",
          placeholder: null,
          value: "0",
          required: true,
          moves_to: 5,
        },
        {
          question_number: "6",
          question: "How many points for a tie?",
          name: "points_for_tie",
          question_type: "text",
          placeholder: null,
          value: 1,
          required: true,
          moves_to: 6,
        },
        {
          question_number: null,
          question:
            "You have completed the form. Please confirm the details below and submit it below to continue!",
          question_type: "finished",
          placeholder: null,
          value: null,
          required: true,
          moves_to: "finished",
        },
      ],
    };
  },
  mounted: function () {
    var vm = this;
    window.addEventListener(
      "keypress",
      function (e) {
        // console.log(e.keyCode);
        if (e.keyCode === 13) {
          var current_question = vm.questions[vm.currentQuestion];
          // console.log("current_question", current_question);
          if (current_question.moves_to !== "finished") {
            if (current_question.value !== null) {
              if (current_question.value !== "") {
                vm.currentQuestion = current_question.moves_to;
                vm.error_message = null;
              } else {
                vm.error_message = "There is an issue with your answer";
              }
            } else {
              vm.error_message = "There is an issue with your answer";
            }
          }
          vm.$nextTick(() => {
            if (vm.$refs.afterClick) {
              vm.$refs.afterClick.focus();
            }
          });
        }
      }.bind(this)
    );
    vm.$refs.afterClick.focus();
  },
  computed: {
    question() {
      return this.questions[this.currentQuestion];
    },
  },
  methods: {
    selectMC: function (question, key) {
      // console.log(question, key);
      question.value = key;
    },
    afterFocus: function () {
      var vm = this;

      vm.$nextTick(() => {
        if (vm.$refs.afterClick) {
          vm.$refs.afterClick.focus();
        }
      });
    },
    checkQuestion: function (value) {
      var vm = this;

      vm.currentQuestion++;
    },
    createEvent: function () {
      var data = {
        questions: this.questions,
      };

      var vm = this;
      this.disableOnClick = true;

      this.$axios
        .post("/api/setup-your-account", {
          questions: vm.questions,
        })
        .then((result) => {
          console.log(result);

          if (result.data.type === "success") {
            // redirect to setup first league
            window.location.replace(
              "/" + result.data.event.type + "/" + result.data.event.slug
            );
          }
          if (result.data.type === "failure") {
            vm.error_message = result.data.reason;
          }
        });
    },
  },
};
</script>

<template>
  <div class="app">
    <router-view />
    <div
      :class="['inline-notification', notificationType]"
      style="z-index: 1000"
      v-if="notificationText"
    >
      {{ notificationText }}
      <span class="close-button" v-on:click="closeNotification"
        ><i class="fas fa-times-circle"></i
      ></span>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
// (function (w,d,s,o,f,js,fjs) {
// 	w['MyWidget']=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
// 	js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
// 	js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
// }(window, document, 'script', 'mw', '//localhost:3123/js/gamebeast-widget.js'));
// mw('init', { showButton: true });
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["notificationType", "notificationText"]),
  },
  method: {
    ...mapActions(["closeNotification"]),
  },
};
</script>

<style lang="scss">
@import "./assets/scss/_reset.scss";
@import "./assets/scss/_grid.scss";
@import "./assets/scss/app.scss";
</style>

<template>
    <div>
        <span class="home">
            <div class="standard-logo-bar">
                <router-link
                    :to="{ name: 'Home' }"
                    style="
                    display: block;
                    max-width: 200px;
                    margin: 0 auto;
                    padding-top: 7px;
                    "
                >
                    <img
                    src="https://cdn.blacktiecollab.com/gamebeast.io/gamebeastlogo.png"
                    style="width: 100%"
                    alt=""
                    />
                </router-link>
            </div>
        </span>
        <div class="is-verified" v-if="isVerified === false">
            Either the code didn't work, or there's something wrong with this link. Try again and then contact us.
        </div>
        <div class="is-verified" v-if="isVerified === true">
            Your account is verified. Check your phone for a follow up text regarding event details!

            <a :href="`https://gamebeast.io/complete-roster/${rosterCode}`" class="button">Go set up your roster</a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isVerified: null,
            rosterCode: null
        }
    },
    mounted: function() {
        var code = this.$route.params.code
        console.log('code', code)
        this.$axios.post(`/api/verify-code/${code}`).then(results => {
            console.log('results', results)
            if(results.data.success === true) {
                this.isVerified = true
                this.rosterCode = results.data.roster_code
            } else {
                this.isVerified = false
            }
        }).catch(err => {
            this.isVerified = false
        })
    }
}
</script>

<style scoped>
.is-verified {
    padding: 100px 30px;
    text-align: center;
}
</style>
<template>
  <td>
    <input type="text" v-model="theModel.first_name" />
    <label v-for="error of v$.modelValue.first_name.$errors" :key="error.$uid">
      <strong style="color: red">{{ error.$message }}</strong>
    </label>
  </td>
  <td>
    <input type="text" v-model="theModel.last_name" />
    <label v-for="error of v$.modelValue.last_name.$errors" :key="error.$uid">
      <strong style="color: red">{{ error.$message }}</strong>
    </label>
  </td>
  <td>
    <input type="text" v-model="theModel.email" />
    <label v-for="error of v$.modelValue.email.$errors" :key="error.$uid">
      <strong style="color: red">{{ error.$message }}</strong>
    </label>
  </td>
  <td>
    <input type="text" v-model="theModel.phone" />
    <label v-for="error of v$.modelValue.phone.$errors" :key="error.$uid">
      <strong style="color: red">{{ error.$message }}</strong>
    </label>
  </td>
  <td>
    <select name="" id="" v-model="theModel.current_rank">
      <option value="A">A</option>
      <option value="B">B</option>
      <option value="C">C</option>
      <option value="D">D</option>
      <option value="E">E</option>
      <option value="Unranked">Unranked</option>
    </select>
  </td>
  <td>
    <a class="button button-small" v-on:click="$emit('removePlayer')">Remove</a>
  </td>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
const mustBeValid = (value) => isValidPhoneNumber(value, "CA");
import { computed } from "vue";
export default {
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const theModel = computed({
      get: () => props.modelValue,
      set: (value) => {
        emit("update:modelValue", value);
      },
    });
    return { v$: useVuelidate(), theModel };
  },
  emits: ["update:modelValue", "removePlayer"],
  validations() {
    return {
      modelValue: {
        email: { required, email },
        first_name: { required },
        last_name: { required },
        phone: {
          required,
          isValid: helpers.withMessage("Invalid Phone Number", mustBeValid),
        },
      },
    };
  },
};
</script>
<style scoped>
.is-verified {
  padding: 100px 30px;
  text-align: center;
}
.dashboard-wrapper {
  margin: 0 auto;
  margin-top: 100px;
  max-width: 1000px;
}

h1 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 15px;
}

h2 {
  font-size: 22px;
  margin-top: 10px;
  font-weight: 700;
}

.button {
  background: #000;
  color: #fff;
  font-weight: 700;
  padding: 15px 20px;
  margin: 10px 5px;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
}

.button-small {
  padding: 5px 10px;
}

.button:hover {
  background: #333;
  transition: 0.5s;
}
.standings-table {
  width: 100%;
}
.standings-table th {
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px;
  background: #ccc;
}

.standings-table td {
  padding: 10px;
  text-align: center;
}

.standings-table input {
  padding: 5px 10px;
}

.standings-table select {
  text-align: center;
  padding: 5px 10px;
}

.table-overflow {
  width: 100%;
  overflow: scroll;
}
</style>

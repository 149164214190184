<template>
  <span>
    <div class="change-leagues-modal" v-if="changeLeaguesOpen">
      <div class="change-leagues-header">Your Events</div>
      <a
        :href="'/' + event.type + '/' + event.slug + '/Schedule'"
        class="league-details"
        v-for="(event, key) in events"
        :key="key"
      >
        <div class="league-details-header">
          {{ event.name }}
        </div>
        <div class="league-details-content">
          {{ event.type }}
          <span><i class="fas fa-caret-right"></i></span>
        </div>
      </a>
      <div class="change-leagues-header">Your Favourites</div>
      <a
        :href="'/' + event.type + '/' + event.slug + '/Schedule'"
        class="league-details"
        v-for="(event, key) in favourites"
        :key="key"
      >
        <div class="league-details-header">
          {{ event.name }}
        </div>
        <div class="league-details-content">
          {{ event.type }}
          <span><i class="fas fa-caret-right"></i></span>
        </div>
      </a>
    </div>
    <div class="login-modal-bg" v-if="loginFormOpen">
      <div class="login-modal">
        <div class="login-modal-header">
          <span v-if="loginForm">Login to your account</span>
          <span v-if="!loginForm">Forgot my password</span>
          <span class="close-button" v-on:click="loginFormOpen = false"
            ><i class="fas fa-times-circle"></i
          ></span>
        </div>
        <div class="login-form" v-if="loginForm">
          <label>
            <span>Email</span>
            <input type="text" name="email" v-model="email" />
          </label>
          <label>
            <span>Password</span>
            <input type="password" name="password" v-model="password" />
          </label>

          <button class="button button-green" v-on:click="userLogin()">
            Login</button
          ><a
            class="button button-purple"
            style="margin-left: 10px"
            v-on:click="loginForm = !loginForm"
            >Forgot Password</a
          >
        </div>
        <div class="login-form" v-if="!loginForm">
          <label>
            <span>Email</span>
            <input type="text" name="email" v-model="email" />
          </label>
          <button class="button button-green" v-on:click="forgotPassword()">
            Send Me A New Password</button
          ><a
            class="button button-purple"
            style="margin-left: 10px"
            v-on:click="loginForm = !loginForm"
            >I remember!</a
          >
        </div>
      </div>
    </div>
    <div class="dashboard-header-bar">
      <div class="logo-row">
        <div class="event-name">
          {{ name }}
        </div>
        <div class="menunav" v-on:click="menuNav = !menuNav">
          <div class="menu-button">
            <i class="fas fa-bars"></i>
          </div>
        </div>
        <div class="menu" v-if="menuNav">
          <div
            class="login-account-button"
            v-on:click="loginFormOpen = !loginFormOpen"
            v-if="!user"
          >
            <div class="login-account-button-text">Login</div>
          </div>
          <div class="login-account-button hidden-p" v-if="user">
            <div class="login-account-button-window" v-if="user">
              <i class="fas fa-circle" style="color: #44c21e"></i
              >&nbsp;&nbsp;&nbsp;{{ user.full_name }}
            </div>
          </div>
          <div
            class="login-account-button"
            v-on:click="changeLeaguesOpen = !changeLeaguesOpen"
          >
            <div class="login-account-button-text">My Favourites</div>
          </div>
          <div
            class="login-account-button"
            v-on:click="saveEventToFavourites()"
            v-if="isFavourite"
          >
            <div class="login-account-button-text">+ Add to Favourites</div>
          </div>
          <a
            href="/create-event"
            v-if="user"
            class="login-account-button"
            style="text-decoration: none"
          >
            Create a New Event
          </a>
          <a
            v-on:click="userLogout()"
            v-if="user"
            class="login-account-button"
            style="text-decoration: none"
          >
            Logout
          </a>
        </div>
        <div class="topnav">
          <div
            class="login-account-button"
            v-on:click="loginFormOpen = !loginFormOpen"
            v-if="!user"
          >
            <div class="login-account-button-text">Login</div>
          </div>
          <div class="login-account-button hidden-p" v-if="user">
            <div class="login-account-button-window" v-if="user">
              <i class="fas fa-circle" style="color: #44c21e"></i
              >&nbsp;&nbsp;&nbsp;{{ user.full_name }}
            </div>
          </div>
          <div
            class="login-account-button"
            v-on:click="changeLeaguesOpen = !changeLeaguesOpen"
          >
            <div class="login-account-button-text">My Favourites</div>
          </div>

          <div
            class="login-account-button"
            v-on:click="saveEventToFavourites()"
            v-if="isFavourite"
          >
            <div class="login-account-button-text">+ Add to Favourites</div>
          </div>
          <a
            href="/create-event"
            v-if="user"
            class="login-account-button"
            style="text-decoration: none"
            >Create a New Event</a
          >
          <a
            v-on:click="logout()"
            v-if="user"
            class="login-account-button"
            style="text-decoration: none"
            >Logout</a
          >
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import _ from "lodash";
import { mapState, mapActions } from "vuex";
export default {
  name: "Header",
  data: function () {
    return {
      email: null,
      password: null,
      loggingIn: false,
      menuNav: false,
      navigationValue: null,
      loginForm: true,
      loginFormOpen: false,
      changeLeaguesOpen: false,
      events: [],
      favourites: [],
      isFavourite: false,
      navigation: [
        {
          icon: '<i class="fas fa-clipboard-list"></i>',
          value: "Teams",
          admin_only: false,
        },
        {
          icon: '<i class="fas fa-list-ol"></i>',
          value: "Standings",
          admin_only: false,
        },
        {
          icon: '<i class="fas fa-calendar-week"></i>',
          value: "Schedule",
          admin_only: false,
        },
        {
          icon: '<i class="fas fa-bullhorn"></i>',
          value: "Announcements",
          admin_only: false,
        },
        {
          icon: '<i class="fas fa-pencil-ruler"></i>',
          value: "Rules",
          admin_only: false,
        },
        {
          icon: '<i class="fas fa-info-circle"></i>',
          value: "Info",
          admin_only: false,
        },
      ],
    };
  },
  // To use props, they must be declared
  props: {
    name: String,
    slug: String,
    type: String,
    user: Object,
    // event: Object,
    // computedAdmin: Boolean
  },
  computed: {},
  mounted: function () {
    if (this.user) {
      this.$axios.get(`/api/your-events/${this.user.id}`).then((results) => {
        this.events = results.data;
      });
    }
    this.favourites = this.getFavourites();
    this.isFavourite = this.checkEventFavourites();
  },
  methods: {
    ...mapActions(["login", "logout", "setNotification"]),
    userLogin: function () {
      var vm = this;
      this.login({
        email: this.email,
        password: this.password,
      })
        .then(() => {
          vm.loginFormOpen = false;
          this.setNotification(["You are now logged in!", "success"]);
        })
        .catch(() => {
          this.setNotification([
            "Login failed. Please try again or contact us.",
            "danger",
          ]);
        });
    },
    forgotPassword: function () {
      let self = this;

      this.$axios
        .post("/reset-password", {
          email: self.email,
        })
        .then((res) => {
          // send success notification about email

          self.$emit("set-notification", [
            "Email has been sent to the appropriate user. Please search your email and click the link inside.",
            "success",
          ]);
        });
    },
    getFavourites: function () {
      if (typeof localStorage !== "undefined") {
        var favourites = localStorage.getItem("favourites");
      }
      if (favourites) {
        var string = JSON.parse(favourites);

        return string;
      }
      return [];
    },
    checkEventFavourites: function () {
      var vm = this;

      var events_favourite = vm.getFavourites();

      if (events_favourite.length > 0) {
        var filter = _.filter(events_favourite, function (fav) {
          return fav.slug === vm.slug;
        });
        if (filter.length > 0) {
          return false;
        }
        return true;
      }

      return true;
    },
    saveEventToFavourites: function () {
      var favourites = localStorage.getItem("favourites");

      const event = { name: this.name, slug: this.slug, type: this.type };

      var string = [];
      var parse = JSON.parse(favourites);

      if (parse) {
        string = parse;
      }

      string.push(event);

      var stringed = JSON.stringify(string);

      localStorage.setItem("favourites", stringed);
      this.favourites = this.getFavourites();
      this.isFavourite = this.checkEventFavourites();

      this.$emit("set-notification", ["Added to favourites!", "success"]);
    },
    userLogout: function () {
      this.logout();
    },
  },
};
</script>

import { createWebHistory, createRouter } from "vue-router";
import store from "../store/index";
import Home from "@/Home.vue";
import SetupYourAccount from "@/SetupYourAccount.vue";
import CreateEvent from "@/CreateEvent.vue";
import Scorekeep from "@/Scorekeep.vue";
import League from "@/Dashboard.vue";
import VerifyCoach from "@/VerifyCoach.vue";
import CompleteRoster from "@/CompleteRoster.vue";
import RosterPlayer from "@/RosterPlayer.vue"

// async function showLoading(message, duration = 10000) {
//     const loading = await loadingController.create({
//       message,
//       duration,
//     });
//     await loading.present();
//     return loading;
// }

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: '/setup-your-account',
        component: SetupYourAccount,
        name: 'Setup Your Account',
        props: { default: true }
    },
    {
        path: '/create-event',
        component: CreateEvent,
        name: 'Create Event',
        props: { default: true }
    },
    { 
        path: '/:type/:slug/:nav?', 
        component: League,
        name: 'Dashboard',
        props: { default: true }
    },
    {
        path: '/scorekeep/:unique_id',
        component: Scorekeep,
        name: 'Scorekeeper',
        props: { default: true }
    },
    {
        path: '/coach-verify/:code',
        component: VerifyCoach,
        name: 'VerifyCoach'
    },
    {
      path: '/complete-roster/:code',
      component: CompleteRoster,
      name: 'CompleteRoster'
    },
    {
      path: '/roster-player/:code',
      component: RosterPlayer,
      name: 'RosterPlayer'
    }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
    if (!store.state.initialize) {
    //   const loading = await showLoading("Please wait...");
      await store.dispatch("refreshToken");
    //   loading.dismiss();
      store.state.initialize = 1;
    }
    if (to.matched.some((record) => record.meta.requiresLogin)) {
      if (!store.state.user) {
        // showToast("Please Login", "warning");
        next({
          path: "/login",
        });
      } else {
        next();
      }
    } else {
      next();
    }
  });
  

export default router;
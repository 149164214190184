<template>
    <div class="navigation">
        <a :class="['navigation-item', { selected: navigationValue === item.value }]" v-for="(item, key) in navigation" v-on:click="selectNavItem(item)" :key="key" >
            <div class="navigation-item-icon" v-html="item.icon">

            </div>
            <div class="navigation-item-text hidden-p">
                {{ item.value }}
            </div>
        </a>
    </div>
</template>


<script>
export default {
    name: 'Navigation',
    data: function () {
        return {
            navigation: [
                {
                    icon: '<i class="fas fa-clipboard-list"></i>',
                    value: 'Teams',
                    admin_only: false
                },
                {
                    icon: '<i class="fas fa-list-ol"></i>',
                    value: 'Standings',
                    admin_only: false
                },
                {
                    icon: '<i class="fas fa-calendar-week"></i>',
                    value: 'Schedule',
                    admin_only: false
                },
                {
                    icon: '<i class="fas fa-bullhorn"></i>',
                    value: 'Announcements',
                    admin_only: false
                },
                {
                    icon: '<i class="fas fa-pencil-ruler"></i>',
                    value: 'Rules',
                    admin_only: false
                },
                {
                    icon: '<i class="fas fa-info-circle"></i>',
                    value: 'Info',
                    admin_only: false
                },
            ],
        }
    },
    // To use props, they must be declared
    props: {
        navigationValue: String
    },
    methods: {
            selectNavItem: function(item) {
                console.log('item', item)
                this.$emit('selectnav', item)
            }
    }
}
</script>


<style lang="scss">
    @import './assets/scss/dashboard.scss';    
</style>

<template>
    <span>
        <div class="dashboard-wrapper" v-if="!event">
            Loading - please wait!
        </div>
    </span>
     <!-- <router-link to="/">
       Home
     </router-link> -->
</template>

<script>
export default {
  data() {
    return {
      greeting: 'Hello Vue!'
    };
  }
}
</script>

<template>
  <div class="dashboard-current-window">
    <div id="adarea">
      <scriptx async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7578943240214333"
      crossorigin="anonymous"></scriptx>
      <!-- Umpire Cheat Sheet Bottom -->
      <ins class="adsbygoogle"
      style="display:block"
      data-ad-client="ca-pub-7578943240214333"
      data-ad-slot="2396693351"
      data-ad-format="auto"
      data-full-width-responsive="true"></ins>
      <scriptx>
          (adsbygoogle = window.adsbygoogle || []).push({});
      </scriptx>
    </div>
    <div class="add-game-modal-bg" v-if="addTeamModal">
      <div class="add-game-modal">
        <div class="add-game-modal-header">
            Add Team
            <span class="close-button" v-on:click="addTeamModal = false"
              ><i class="fas fa-times-circle"></i
            ></span>
          </div>
        
        <div class="game-modal-rows">
          <div class="overflow-table-scroll">
            <table class="standings-table">
              <tr class="table-header">
                <td v-if="computedAdmin">Division</td>
                <td style="width: 225px">Team Name</td>
                <td v-if="computedAdmin">Shortcode</td>
                
                <td v-if="computedAdmin">Coach Full Name</td>
                <td v-if="computedAdmin">Coach Email</td>
                <td v-if="computedAdmin">Coach Phone</td>
                <td v-if="computedAdmin">Onboard</td>
              </tr>
              <tr>
                <td>
                  <input type="text" class="league-edit display" v-model="addTeam.division_name">
                </td>
                <td>
                  <input type="text" class="league-edit display" v-model="addTeam.name">
                </td>
                <td>
                  <input type="text" class="league-edit display" v-model="addTeam.shortcode">
                </td>
                <td>
                  <input type="text" class="league-edit display" v-model="addTeam.coach_full_name">
                </td>
                <td>
                  <input type="text" class="league-edit display" v-model="addTeam.coach_email">
                </td>
                <td>
                  <input type="text" class="league-edit display" v-model="addTeam.coach_phone">
                </td>
                <td>
                  <input type="checkbox" v-model="addTeam.onboard">
                </td>
              </tr>
            </table>
            <br><br>
            <a
              class="button button-purple"
              style="margin-left: 10px"
              v-on:click="storeTeam()"
            >
              Save Team
            </a>
          </div>
        </div>
      </div>
    </div>
    <div :class="['tool-bar', { mobile: isMobile }]" v-if="computedAdmin">
      <a class="button" v-on:click="addTeamModal = !addModal"><i class="fas fa-plus"></i> Add Team</a>
    </div>
    <div
      class="division-row"
      v-for="(division, key) in computedDivisions"
      :key="key"
    >
      <div class="division-header">{{ division.division_name }} Teams</div>
      <div class="overflow-table-scroll">
        <table class="standings-table">
          <tr class="table-header">
            <td style="width: 225px">Team Name</td>
            <td v-if="computedAdmin">Shortcode</td>
            <td v-if="computedAdmin">Coach Full Name</td>
            <td v-if="computedAdmin">Coach Email</td>
            <td v-if="computedAdmin">Coach Phone</td>
            <td v-if="computedAdmin">Phone Verified</td>
            <td v-if="computedAdmin">Amount Paid</td>
            <!-- <td v-if="computedAdmin && team">Roster Count</td> -->
            <td v-if="computedAdmin">Actions</td>
          </tr>
          <tr v-for="(team, team_key) in division.teams" :key="team_key">
            <td style="width: 225px">
              <span v-if="editTeam !== team"><b>{{ team.name }}</b></span>
              <input
                class="league-edit display"
                type="text"
                v-if="editTeam === team"
                v-model="editTeam.name"
              />
            </td>
            <td v-if="computedAdmin">
              <span v-if="editTeam !== team">{{ team.shortcode }}</span>
              <input
                class="league-edit display"
                type="text"
                v-if="editTeam === team"
                v-model="editTeam.shortcode"
              />
            </td>
            <td v-if="computedAdmin">
              <span v-if="editTeam !== team">{{ team.coach_full_name }}</span>
              <input
                class="league-edit display"
                type="text"
                v-if="editTeam === team"
                v-model="editTeam.coach_full_name"
              />
            </td>
            <td v-if="computedAdmin">
              <span v-if="editTeam !== team">{{ team.coach_email }}</span>
              <input
                class="league-edit display"
                type="text"
                v-if="editTeam === team"
                v-model="editTeam.coach_email"
              />
            </td>
            <td v-if="computedAdmin">
              <span v-if="editTeam !== team">{{
                team.coach_phone
              }}</span>
              <input
                class="league-edit display"
                type="text"
                v-if="editTeam === team"
                v-model="editTeam.coach_phone"
              />
            </td>
            <td v-if="computedAdmin">
              {{
                team.phone_verified ? 'Yes' : 'No'
              }}
            </td>
             <td v-if="computedAdmin">
              <span v-if="editTeam !== team">{{
                team.amount_paid
              }}</span>
              <input
                class="league-edit display"
                type="text"
                v-if="editTeam === team"
                v-model="editTeam.amount_paid"
              />
            </td>
            <!-- <td v-if="computedAdmin && team">
              <span v-if="editTeam !== team">{{
                team.roster.length
              }}</span>
            </td> -->
            <td v-if="computedAdmin">
              <div
                class="button button-purple"
                v-on:click="editTeam = team"
                v-if="editTeam !== team"
              >
                Edit Team
              </div>
              <div
                class="button button-blue"
                v-on:click="saveTeamDetails()"
                v-if="editTeam === team"
              >
                Save Team
              </div>
              <div
                class="button button-red"
                v-on:click="deleteTeam(team)"
                v-if="editTeam !== team"
              >
                Delete Team
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="division-row" v-if="computedAdmin" style="margin-top: 100px">
      <div class="division-header">Generate a Mailing List</div>
      <div class="row" style="margin-top: 20px">
        <div class="col-md-2">
          <div class="mailing-list-filters">
            <div class="step">
              <div class="step-header">Filter By Division</div>
              <div class="step-content">
                <div
                  :class="[
                    'sidebar-choice',
                    { selected: selectedDivisionEmailFilter === null },
                  ]"
                  v-on:click="selectedDivisionEmailFilter = null"
                >
                  All Divisions
                </div>
                <div
                  :class="[
                    'sidebar-choice',
                    { selected: division === selectedDivisionEmailFilter },
                  ]"
                  v-for="(division, key) in keyFilters.division_id"
                  v-on:click="selectedDivisionEmailFilter = division"
                  :key="key"
                >
                  {{ division }}
                </div>
              </div>
            </div>
            <div class="step">
              <div class="step-header">Filter By Coach</div>
              <div class="step-content">
                <div
                  :class="[
                    'sidebar-choice',
                    { selected: selectedCoachEmailFilter === null },
                  ]"
                  v-on:click="selectedCoachEmailFilter = null"
                >
                  All Teams
                </div>
                <div
                  :class="[
                    'sidebar-choice',
                    { selected: selectedCoachEmailFilter === 'primary' },
                  ]"
                  v-on:click="selectedCoachEmailFilter = 'primary'"
                >
                  Primary
                </div>
                <div
                  :class="[
                    'sidebar-choice',
                    { selected: selectedCoachEmailFilter === 'secondary' },
                  ]"
                  v-on:click="selectedCoachEmailFilter = 'secondary'"
                >
                  Secondary
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="step" style="margin-left: 10px">
            <div class="step-header">Filter By Team</div>
            <div class="step-content">
              <div
                :class="[
                  'sidebar-choice',
                  { selected: selectedTeamEmailFilter === null },
                ]"
                v-on:click="selectedTeamEmailFilter = null"
              >
                All Teams
              </div>
              <div
                :class="[
                  'sidebar-choice',
                  { selected: team === selectedTeamEmailFilter },
                ]"
                v-for="(team, key) in teams"
                :key="key"
                v-on:click="selectedTeamEmailFilter = team"
              >
                {{ team.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-7">
          <div style="padding: 20px">
            <p>
              <b>Copy this list and paste it into your email sending list:</b>
            </p>
            <textarea
              style="width: 100%; height: 400px"
              v-model="computedEmailList"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
var mobile = require("is-mobile");
export default {
  name: "TeamPage",
  // To use props, they must be declared
  data: function () {
    return {
      addTeamModal: false,
      selectedDivisionEmailFilter: null,
      selectedTeamEmailFilter: null,
      selectedCoachEmailFilter: null,
      editTeam: null,
      addTeam: {
        name: null,
        shortcode: null,
        division_name: null,
        coach_full_name: null,
        coach_email: null,
        coach_phone: null,
        onboard: false
      }
    };
  },
  props: {
    computedAdmin: Boolean,
    teams: Array,
    eventId: Number,
  },
  computed: {
    isMobile() {
      return mobile();
    },
    computedEmailList: function () {
      var vm = this;

      var teams = vm.teams;
      var filtered_teams = teams;
      if (vm.selectedTeamEmailFilter) {
        filtered_teams = _.filter(teams, function (team, index) {
          return team.id === vm.selectedTeamEmailFilter.id;
        });
      }

      if (vm.selectedDivisionEmailFilter) {
        filtered_teams = _.filter(filtered_teams, function (team, index) {
          return team.division_name === vm.selectedDivisionEmailFilter;
        });
      }

      var email_name_list = [];

      var i = 0;

      for (i; i < filtered_teams.length; i++) {
        if (filtered_teams[i].coach_email !== "") {
          if (vm.selectedCoachEmailFilter !== "secondary") {
            email_name_list.push(filtered_teams[i].coach_email);
          }
        }
        if (filtered_teams[i].secondary_contact_email !== "") {
          if (vm.selectedCoachEmailFilter !== "primary") {
            email_name_list.push(filtered_teams[i].secondary_contact_email);
          }
        }
      }

      return email_name_list;
    },
    computedDivisions: function () {
      // take all the teams, pull divisions via a grouping
      var vm = this;
      var teams = vm.teams;

      var group = _.groupBy(teams, "division_name");
      var divisions = Object.keys(group);

      var array = [];

      var i = 0;

      for (i; i < divisions.length; i++) {
        var division = divisions[i];

        array.push({
          division_name: division,
          teams: [],
        });
      }

      var o = 0;

      for (o; o < array.length; o++) {
        division = array[o];

        var filtered_teams = _.filter(teams, function (team) {
          return team.division_name === division.division_name;
        });

        array[o].teams = filtered_teams;
      }

      return array;
    },
    keyFilters: function () {
      var vm = this;

      var object = {};

      var filters = ["division_id"];

      filters.map((filter) => {
        var keyBy = _.keyBy(vm.games, filter);
        var keys = Object.keys(keyBy);

        object[filter] = keys;
      });
      return object;
    },
  },
  methods: {
    storeTeam: function() {
      var vm = this
      this.$axios.post('/api/store-team', {
        team: vm.addTeam,
        event: vm.eventId
      }).then(results => {
        if(results.data.success === true) {
          var string = null
          if(vm.addTeam.onboard === true) {
            string = 'and texted'
          }
          vm.$emit("set-notification", [
            "Team was added " + string,
            "success",
          ]);
          this.addTeamModal = false
          this.addTeam = {
            name: null,
            shortcode: null,
            division_name: null,
            coach_full_name: null,
            coach_email: null,
            coach_phone: null,
            onboard: false
          }
        }
      })
    },
    deleteTeam: function(team) {
      var vm = this
      var r = confirm('Are you sure you want to do this? This will delete the team and any games they are connected to.')

      if(r === true) {
        this.$axios.post('/api/delete-team', {
          team: team
        }).then((result) => {
          if(result.data.success === true) {
            vm.$emit("set-notification", [
              "Team was deleted. Refresh to see the changes.",
              "success",
            ]);
          }
        })
      }
    },
    saveTeamDetails: function () {
      var vm = this;

      this.$axios
        .post("/api/save-team", {
          team: vm.editTeam,
          event: vm.eventId,
        })
        .then((result) => {
          console.log(result);
          if (result.data.type === "success") {
            vm.editTeam = null;
            vm.$emit("set-notification", [
              "Team was successfully edited.",
              "success",
            ]);
          } else {
            vm.$emit("set-notification", [
              "Something went wrong. If this persists, please contact us.",
              "danger",
            ]);
          }
        });
    },
  },
};
</script>

<template>
  <div>
    <span class="home">
      <div class="standard-logo-bar">
        <router-link
          :to="{ name: 'Home' }"
          style="
            display: block;
            max-width: 200px;
            margin: 0 auto;
            padding-top: 7px;
          "
        >
          <img
            src="https://cdn.blacktiecollab.com/gamebeast.io/gamebeastlogo.png"
            style="width: 100%"
            alt=""
          />
        </router-link>
      </div>
    </span>
    <div class="dashboard-wrapper">
      <div class="adswrapper">
        <Adsense
          data-ad-client="ca-pub-7578943240214333"
          data-ad-slot="2396693351"
        >
        </Adsense>
      </div>
    </div>
    <div class="dashboard-wrapper">
      <h1>Complete your roster!</h1>
      <p>
        Fill out the roster below yourself, or send the form to each of your
        teammates to fill out.
      </p>
      <p>
        For "Current Rank" - please enter the rank you are listed at - and
        answer honestly - these will be cross referenced!
      </p>
      <br /><br />
      <div style="text-align: center">
        <h2>Option A - Have Your Players Enter Their Info</h2>
        <a v-on:click="copyPlayerLink()" class="button"
          >Click here to copy the form to send to your players</a
        >
        <p>
          <b
            >Note: You are still responsible for all team members filling out
            this form.</b
          >
        </p>
      </div>
      <br />
      <hr />
      <br />
      <div style="text-align: center">
        <h2>Option B - You Enter Their Info</h2>
        <p>Please make sure you include yourself if you are playing.</p>
      </div>

      <a class="button" v-on:click="addPlayer()">Add a Player</a>
      <div class="table-overflow">
        <table class="standings-table">
          <tr>
            <!-- <th>Waiver</th> -->
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Current Rank</th>
            <th></th>
          </tr>
          <tr v-for="(player, key) of players" :key="key">
            <TeamForm
              v-model="players[key]"
              :key="key"
              @removePlayer="removePlayer(key, player.id)"
            ></TeamForm>
          </tr>
        </table>
      </div>
      <a class="button" v-if="players.length > 0" v-on:click="addPlayer()"
        >Add a Player</a
      >
      <p style="text-align: center">
        This page will save automatically provided you are connected to the
        internet.
      </p>

      <!-- fill out your roster -->
    </div>
  </div>
  <div class="dashboard-wrapper">
    <div class="adswrapper">
      <Adsense
        data-ad-client="ca-pub-7578943240214333"
        data-ad-slot="9145934530"
      >
      </Adsense>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import TeamForm from "./components/TeamForm.vue";
const mustBeValid = (value) => isValidPhoneNumber(value, "CA");
export default {
  data() {
    return {
      players: [],
    };
  },
  mounted: function () {
    var code = this.$route.params.code;
    this.$axios.get(`/api/grab-roster/${code}`).then((results) => {
      if (results.data.success === true) {
        this.players = results.data.players;
      }
    });
  },
  watch: {
    players: {
      deep: true,
      handler() {
        this.savePlayers();
      },
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  methods: {
    addPlayer: function () {
      if (this.players.length > 0) {
        this.v$.$touch();
        if (this.v$.$error) {
          console.log("errors", this.v$.$errors);
          window.scrollTo(0, 0);
          return;
        }
      }
      var code = this.$route.params.code;
      this.$axios
        .post("/api/add-player", {
          code: code,
        })
        .then((results) => {
          if (results.data.success === true) {
            this.players.push({
              id: results.data.id,
              first_name: null,
              last_name: null,
              email: null,
              phone: null,
              current_rank: "E",
            });
          }
        });
    },
    removePlayer: function (key, id) {
      this.$axios
        .post("/api/remove-player", {
          id: id,
        })
        .then((results) => {
          if (results.data.success === true) {
            this.players.splice(key, 1);
          }
        });
    },
    savePlayers: function () {
      var code = this.$route.params.code;
      this.$axios
        .post("/api/update-roster", {
          code: code,
          players: this.players,
        })
        .then((results) => {
          console.log("results", results);
        });
    },
    copyPlayerLink: async function () {
      var code = this.$route.params.code;
      await navigator.clipboard.writeText(
        `https://gamebeast.io/roster-player/${code}`
      );
      alert(
        "You have copied the link to your device! You can paste it in your group chat, email or text message to alert your players!"
      );
    },
  },
  components: { TeamForm },
};
</script>

<style scoped>
.is-verified {
  padding: 100px 30px;
  text-align: center;
}
.dashboard-wrapper {
  margin: 0 auto;
  margin-top: 100px;
  max-width: 1000px;
}

h1 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 15px;
}

h2 {
  font-size: 22px;
  margin-top: 10px;
  font-weight: 700;
}

.button {
  background: #000;
  color: #fff;
  font-weight: 700;
  padding: 15px 20px;
  margin: 10px 5px;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  text-align: center;
}

.button-small {
  padding: 5px 10px;
}

.button:hover {
  background: #333;
  transition: 0.5s;
}
.standings-table {
  width: 100%;
}
.standings-table th {
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  padding: 10px;
  background: #ccc;
}

.standings-table td {
  padding: 10px;
  text-align: center;
}

.standings-table input {
  padding: 5px 10px;
}

.standings-table select {
  text-align: center;
  padding: 5px 10px;
}

.table-overflow {
  width: 100%;
  overflow: scroll;
}
</style>

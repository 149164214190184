<template>
  <span class="home">
    <div class="standard-logo-bar">
      <router-link
        :to="{ name: 'Home' }"
        style="
          display: block;
          max-width: 200px;
          margin: 0 auto;
          padding-top: 7px;
        "
      >
        <img
          src="https://cdn.blacktiecollab.com/gamebeast.io/gamebeastlogo.png"
          style="width: 100%"
          alt=""
        />
      </router-link>
    </div>
    <div
      :class="['inline-notification', notificationType]"
      v-if="notificationText"
    >
      {{ notificationText }}
      <span class="close-button" v-on:click="notificationText = null"
        ><i class="fas fa-times-circle"></i
      ></span>
    </div>

    <div class="main-header-area">
      <div class="main-header-title">
        Unleash your league or tournament here.
      </div>
      <div class="secondary-header-title">
        Track your schedule, teams, scores and standings with ease.
      </div>
      <div class="select-row">
        <div class="row">
          
          <div class="col-md-6">
            <div class="login-box bonus-margin">
              <div class="league-header">Search for Your League</div>
              <div class="league-content">
                <p>
                  Search for your league by the shortcode provided by your event
                  co-ordinator.
                </p>
                <label for="">
                  <span>Enter your shortcode</span>
                  <input
                    v-model="shortcode"
                    type="text"
                    name="shortcode"
                    placeholder="Search using the shortcode
                      "
                  />
                </label>
                <button class="search-button" v-on:click="searchForLeague()">
                  Search Now
                </button>

                <div class="padding-header">Previously Favourited</div>
                <div class="padding-content">
                  <router-link
                    :to="'/' + event.type + '/' + event.slug + '/Schedule'"
                    class="league-details"
                    v-for="(event, key) in computeFavourites"
                    :key="key"
                  >
                    <div class="league-details-header">
                      {{ event.name }}
                    </div>
                    <div class="league-details-content">
                      {{ event.type }}
                      <span><i class="fas fa-caret-right"></i></span>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="register-box">
              <div class="register-now" v-if="!user">
                <div class="register-now-header" style="font-size: 40px">
                  Register For Free!
                </div>
                <div class="register-now-button">
                  <router-link
                    :to="{ name: 'Setup Your Account' }"
                    href="/register"
                    class="outside-button button-green"
                  >
                    Register
                  </router-link>
                </div>
              </div>
              <div class="login-now-box">
                <div v-if="user">
                  <div class="login-now-header">
                    Welcome, {{ user.full_name }}
                  </div>
                  <div class="" style="max-height: 425px; overflow-y: scroll">
                    <div
                      class="event-row"
                      v-for="event of yourEvents"
                      :key="event.id"
                    >
                      <div class="row">
                        <div class="col-md-9">
                          {{ event.name }}
                        </div>
                        <div class="col-md-3">
                          <a
                            :href="`/league/${event.slug}/Schedule`"
                            class="btn"
                            >Go</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="!user">
                  <div class="login-now-header">Login Now</div>
                  <div class="login-now-content">
                    <label for="">
                      <span>Email Address</span>
                      <input type="text" name="email" v-model="email" />
                    </label>
                    <label for="">
                      <span>Password</span>
                      <input
                        type="password"
                        name="password"
                        v-model="password"
                      />
                    </label>
                    <button
                      class="outside-button button-purple"
                      v-on:click="userLogin()"
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="icons-row">
        <div class="row">
          <div class="col-md-4">
            <div class="front-icon">
              <div class="icon">
                <i class="fas fa-users"></i>
              </div>
              Teams can report their own scores
            </div>
          </div>
          <div class="col-md-4">
            <div class="front-icon">
              <div class="icon">
                <i class="fas fa-clipboard-list"></i>
              </div>
              Auto-updating standings
            </div>
          </div>
          <div class="col-md-4">
            <div class="front-icon">
              <div class="icon">
                <i class="fas fa-umbrella"></i>
              </div>
              Postpone/Reschedule reports
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="front-icon">
              <div class="icon">
                <i class="fas fa-mobile-alt"></i>
              </div>
              Mobile App & Website
            </div>
          </div>
          <div class="col-md-4">
            <div class="front-icon">
              <div class="icon">
                <i class="fas fa-user-tag"></i>
              </div>
              Free to Use, Premium TBA
            </div>
          </div>
          <div class="col-md-4">
            <div class="front-icon">
              <div class="icon">
                <i class="fas fa-upload"></i>
              </div>
              Upload <u>ANY</u> Schedule CSV
            </div>
          </div>
        </div>
      </div>
      <div class="content-area">
        <div class="pricing-header">Pricing That Starts With <u>Zero</u></div>
        <div class="pricing-content">
          <div class="row">
            <div class="col-md-12">
              <div class="login-box">
                <div class="price-header">Free</div>
                <div class="free-reason">
                  <span class="plus">+</span>Super easy schedule setup
                </div>
                <div class="free-reason">
                  <span class="plus">+</span>Teams can report their scores
                </div>
                <div class="free-reason">
                  <span class="plus">+</span>No complex passwords to access
                </div>
                <div class="free-reason">
                  <span class="plus">+</span>Easy to use interface
                </div>
                <div class="free-reason">
                  <span class="plus">+</span>Mobile App
                </div>
                <div class="free-reason">
                  <span class="plus">+</span>Postpone & reschedule games, run
                  reports
                </div>
                <div class="free-reason">
                  <span class="plus">+</span>Upload any format of CSV
                </div>
                <div class="free-reason">
                  <span class="plus">+</span>More coming!
                </div>
                <router-link
                  :to="{ name: 'Setup Your Account' }"
                  class="outside-button button-green"
                  style="margin-top: 20px"
                >
                  Register Now!
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-area">
        <div class="faq-header">Frequently Asked Questions</div>
        <div class="faq-content">
          <div class="q-a">
            <div class="question">Is GameBeast free?</div>
            <div class="answer">
              <p>
                It is! And we intend to make sure it stays free forever by
                creating a sustainable premium product.
              </p>
              <p>
                Early adopters will receive the ability to test premium features
                for free, and will be given a discount when offered to sign up
                for premium.
              </p>
            </div>
          </div>
          <div class="q-a">
            <div class="question">
              Will there be advertising on the free platform?
            </div>
            <div class="answer">
              <p>
                We don't intend to use adservers at any point with GameBeast in
                the future.
              </p>
              <p>
                We will however offer services to convenors to advertise their
                events on our platform for small nominal fees.
              </p>
              <p>
                These will be in the form of graphical event posters that appear
                in the bottom right of the screen and connect with the convenor
                directly.
              </p>
              <p>
                These will not be posters of your direct competitors. Leagues
                will be shown tournament posters, and tournaments will be shown
                league posters, so there's no overlap.
              </p>
              <p>
                Premium members will be able to serve their own ads in their own
                events to continue to grow dedication in their fanbase for free,
                and they will not receive outside event ads.
              </p>
            </div>
          </div>
          <div class="q-a">
            <div class="question">
              What can you tell us so far about premium?
            </div>
            <div class="answer">
              <p>
                Premium features are still underdevelopment. Register your
                account, and we will update you on premium features as they
                become available!
              </p>
            </div>
          </div>
          <div class="q-a">
            <div class="question">What are auto-updating standings?</div>
            <div class="answer">
              <p>
                Auto updating standings is a feature that when a score is
                reported, the standings will update appropriately.
              </p>
              <p>
                Event co-ordinators can edit scores, which will undo the
                standings created previously, and apply the new standings,
                keeping you up to date without lifting a finger.
              </p>
              <p>We still allow you to edit your standings manually, too.</p>
            </div>
          </div>
          <div class="q-a">
            <div class="question">Do team owners need accounts?</div>
            <div class="answer">
              <p>
                Nope! When uploading your schedule, teams will be automatically
                generated from the schedule list. Those teams will have auto
                generated shortcodes, or you can edit them and create your own.
              </p>
              <p>
                Teams simply need to remember their shortcode, for example
                "JF20", when entering their score. No log in needed!
              </p>
            </div>
          </div>
          <div class="q-a">
            <div class="question">What does upload any CSV schedule mean?</div>
            <div class="answer">
              <p>
                Every league uses a different scheduling software. We do require
                a few rules followed, like a CSV header row, but we can accept
                any scheduling software's CSV and turn it into a finished game
                list!
              </p>
              <p>
                And if you manage to trick us, we'll upload it for you and build
                it into our software!
              </p>
              <p>
                We also provide a standard CSV file to work with if you want to
                just use it instead!
              </p>
            </div>
          </div>
          <div class="q-a">
            <div class="question">Looking for jerseys?</div>
            <div class="answer">
              <p>
                Look no further: <a href="https://teamgear.ca">TeamGear - Full Sublimation Custom jerseys</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      searchTerm: null,
      email: null,
      password: null,
      notificationType: null,
      notificationText: null,
      shortcode: null,
      yourEvents: [],
    };
  },
  computed: {
    computeFavourites: function () {
      if (typeof localStorage !== "undefined") {
        var favourites = localStorage.getItem("favourites");
      }
      if (favourites) {
        var string = JSON.parse(favourites);

        return string;
      }
      return [];
    },
    ...mapState(["user"]),
  },
  mounted: function () {
    this.getUserEvents();
  },
  methods: {
    ...mapActions(["login", "setNotification"]),
    searchForLeague: function () {
      var vm = this;
      this.$axios
        .post("/api/shortcode", { shortcode: this.shortcode })
        .then((res) => {
          console.log(res.data);
          this.$router.push("/league/" + res.data.slug + "/Schedule");
        });
      // this.$axios.get("/api/find-league/" + this.shortcode).then((results) => {
      //   console.log("res", results);

      //   this.$router.push("/league/" + results.data.event.slug + "/Schedule");
      // });
    },
    getUserEvents() {
      if (this.user) {
        this.$axios.get(`/api/your-events/${this.user.id}`).then((results) => {
          console.log("results", results);
          this.yourEvents = results.data;
        });
      }
    },
    userLogin: function () {
      var vm = this;
      this.notificationText = null;
      this.notificationType = null;
      this.login({
        email: this.email,
        password: this.password,
      })
        .then(() => {
          // this.v$.$reset();
          this.email = this.password = null;
          this.getUserEvents();
          vm.loginFormOpen = false;
          this.setNotification([
            "You are now logged in! You can now search for your league and edit it.",
            "success",
          ]);
        })
        .catch((err) => {
          this.setNotification([
            "Login failed. Please try again or contact us.",
            "danger",
          ]);
        });

      // this.$axios
      //   .post("/auth/attempt", {
      //     email: vm.email,
      //     password: vm.password,
      //   })
      //   .then(async (results) => {
      //     const user = results.data.user;

      //     if (results.data.type === "success") {
      //       await sessionStorage.setItem("user", JSON.stringify(user));
      //       // save the user in local storage
      //     } else {
      //       vm.notificationText =
      //         "Login failed. Please try again or contact us.";
      //       vm.notificationType = "danger";
      //     }
      //   });
    },
  },
};
</script>

<style lang="scss">
@import "./assets/scss/welcome.scss";
</style>
